<template>
  <div>
    <b-button
      :id="`review-modal-button-${review.id}`"
      @click="showModal(review.id)"
      variant="primary"
      class="mx-2"
    >
      {{ I18n.t("content", locale_scope) }}
    </b-button>
    <b-modal
      :ref="`review-modal-${review.id}`"
      :id="`review-modal-${review.id}`"
      scrollable
      centered
      ok-only
      no-stacking
      size="xl"
      :title-html="title"
      @shown="loadContent"
    >
      <div :id="`review-modal-${review.id}-content`"></div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
export default {
  props: {
    review: {
      type: Object,
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      I18n,
      locale_scope: {
        scope: "activerecord.attributes.review",
      },
      url: `${window.location.origin}/reviews/${this.review.id}/modal`,
    };
  },
  methods: {
    showModal(review_id) {
      this.$refs[`review-modal-${review_id}`].show();
    },
    loadContent(bvModalEvent) {
      axios.get(this.url).then(({ data }) => {
        // response html含有vue component，需再讓vue處理並mount才能正常顯示
        const response_component = new Vue({
          render(h) {
            return h({
              template: `<div>${data}</div>`,
            });
          },
        }).$mount();
        $(`#${bvModalEvent.componentId}-content`).html(response_component.$el);
      });
    },
  },
};
</script>
