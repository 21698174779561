<template>
  <div>
    <table class="table table-striped table-hover w-100 text-center">
      <thead>
        <tr>
          <th scope="col">{{ I18n.t('title', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('description', locale_scope) }}</th>
          <th scope="col">{{ I18n.t('action') }}</th>
          <th scope="col">{{ I18n.t('status', index_scope) }}</th>
          <th scope="col">{{ I18n.t('operator', index_scope) }}</th>
          <th scope="col">{{ I18n.t('latest_updated_at', index_scope) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="api_function in api_functions" :key="api_function.id">
          <td class="align-middle">{{ api_function.title }}</td>
          <td scope="col" class="align-middle">{{ api_function.description }}</td>
          <td scope="col" class="align-middle">
            <button class="btn btn-primary" @click.prevent="sendApiRequest(api_function)"> {{ I18n.t('execute', index_scope) }}</button>
          </td>
          <td scope="col" class="align-middle">{{ api_function.status }}</td>
          <td scope="col" class="align-middle">{{ api_function.latest_used_manager }}</td>
          <td scope="col" class="align-middle">{{ api_function.latest_updated_at }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from "sweetalert2";

  export default {
    props: {
      api_functions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.api_function'
        },
        index_scope: {
          scope: 'admins.api_functions.index'
        },
        key: 0
      }
    },
    mounted() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = this.authenticity_token
    },
    methods: {
      sendApiRequest(api_function) {
        api_function.status = 'processing'
        this.$forceUpdate() // 強迫 component re-render 讓 processing 狀態可顯示在畫面上
        this.showAlert() // 轉圈圈動畫

        axios.put(`${window.location.pathname}/${api_function.id}/call`).then((response) => {
          const responseSuccess = response.data.success
          const toastType = response.data.flash_type
          const toastMessage = response.data.message
          Toastr(toastType, toastMessage)

          if(!responseSuccess) return

          api_function.status = response.data.status
          api_function.latest_used_manager = response.data.latest_used_manager
          api_function.latest_updated_at = response.data.latest_updated_at
          this.$forceUpdate() // 強迫 component re-render 讓最終狀態可顯示在畫面上
        }).catch((error) => {
          Toastr('error', error)
        }).finally(() => {
          // 關閉動畫
          Swal.close()
        })
      },
      showAlert() {
        Swal.fire({
          title: '資料更新中，請稍候',
          html: '<div class="lds-ring">\
                  <div></div>\
                  <div></div>\
                  <div></div>\
                  <div></div>\
                </div>',
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        })
      }
    }
  }
</script>
