<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('tax_percent', locale_scope) }}
    </div>
    <div class="card-body">
      <ValidationProvider
        :rules="{ required: true }"
        :name="I18n.t('tax_percent', locale_scope)"
        v-slot="{ errors }"
        immediate
      >
        <div class="form-group">
          <div class="input-group">
            <input type="hidden" name='contract[tax_percent]' v-model="contract.tax_percent">
            <multiselect
              v-model.trim="contract.tax_percent"
              :options="tax_options"
              :placeholder="I18n.t('helpers.select.prompt')"
              :show-labels="false"
              :taggable="true"
              :allow-empty="false"
              :tag-placeholder="I18n.t('create_option')"
              @tag="addTax"
            >
              <template slot="noOptions">
                {{ I18n.t('list_empty') }}
              </template>
            </multiselect>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      contract: {
        type: Object
      },
      tax_options: {
        type: Array
      },
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
      }
    },
    mounted() {
      // props 傳入時 this.contract.tax_percent 會是字串，但是 options 的 value 是 float，vue model 抓不到 default option
      // 在此先將 string 轉成 float， ex '5.0' -> 5.0
      this.contract.tax_percent = parseFloat(this.contract.tax_percent)
    },
    methods: {
      addTax(new_tax) {
        if(Number(new_tax)) {
          this.contract.tax_percent = new_tax
          this.tax_options.push(new_tax)
        } else {
          this.contract.tax_percent = ''
        }
      },
    },
  }
</script>
