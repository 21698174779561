<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "0", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="year_month" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('year_month', locale_scope) }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        locale_scope: { scope: 'activerecord.attributes.billing_file_folder' },
        dataTable: null,
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: { url: `${window.location.pathname}.json` },
          language: { url: `/datatable.${I18n.currentLocale()}.lang.json` },
        })
        .api().columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>
