<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "2", "desc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="uid" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('uid', locale_scope) }}
          </th>
          <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('created_at') }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String,
        required: true
      },
      ids: {
        type: Array
      },
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
        dataTable: null,
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url,
            data: (d) => {
              d.id = this.ids
            },
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).api().columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>
