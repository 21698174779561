import tippy from 'tippy.js';
document.addEventListener("DOMContentLoaded", () => {
  tippy('[data-toggle="tooltip"]', {
    content(reference) {
      const title = reference.getAttribute('title');
      reference.removeAttribute('title');
      return title;
    },
  });
});
