<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>{{ I18n.t('activerecord.attributes.company.currency') }}</label>
              <div class="form-control">{{ I18n.t(`simple_form.labels.company.currency.${currency}`) }}</div>
              <small class="text-muted d-block">如需變更幣別，請至公司編輯頁面</small>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="contract_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <date-picker :name="'contract[start_at]'" :current_date="contract.start_at" @changeDate="setStartAt"></date-picker>
            </div>
            <span class="text-danger" v-show='timeError'>{{ this.timeErrorMessage }}</span>
          </div>

          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="contract_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
              <date-picker :name="'contract[end_at]'" :current_date="contract.end_at" @changeDate="setEndAt"></date-picker>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label for="contract_description">{{ I18n.t('description', locale_scope) }}</label>
              <input type="text" id="contract_description" class="form-control " name='contract[description]' v-model.trim="contract.description">
            </div>
          </div>
        </div>
      </div>
    </div>

    <company-accounts-list :company_accounts="company_accounts" :contract="contract"></company-accounts-list>
    <contract-discount :contract="contract" :service_commit_group_enable="service_commit_group_enable"></contract-discount>
    <contract-support-form :contract="contract" :blank_contract_support="blank_contract_support"></contract-support-form>
    <!-- <contract-marketplace-form :contract="contract"></contract-marketplace-form> -->
    <contract-exchange-rate :contract="contract" :rate_categories="rate_categories" :rate_dates="rate_dates"></contract-exchange-rate>
    <contract-tax :contract="contract" :tax_options="tax_options"></contract-tax>
    <!-- <contract-commit-fee :contract="contract" :commit_group_by_contract="commit_group_by_contract"></contract-commit-fee> -->
    <comment-form :commentable="'contract'" :comments="comments" :comment_categories="comment_categories"></comment-form>
  </div>
</template>

<script>
  export default {
    props: {
      contract_form: {
        type: Object,
        requred: true
      },
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
        contract: this.contract_form.contract,
        currency: this.contract_form.currency,
        rate_categories: this.contract_form.rate_categories,
        rate_dates: this.contract_form.rate_dates,
        tax_options: this.contract_form.tax_options,
        blank_contract_support: this.contract_form.blank_contract_support,
        company_accounts: this.contract_form.company_accounts,
        comments: this.contract_form.contract.comments,
        comment_categories: this.contract_form.comment_categories,
        commit_group_by_contract: this.contract_form.commit_group_by_contract,
        timeError: true,
        timeErrorMessage: '',
        service_commit_group_enable: this.contract_form.service_commit_group_enable
      }
    },
    methods: {
      setStartAt(start_at) {
        this.contract.start_at = start_at
      },
      setEndAt(end_at) {
        this.contract.end_at = end_at
      },
    },
    computed: {
      listtenTimeChange() {
        return { start_at: this.contract.start_at, end_at: this.contract.end_at }
      }
    },
    watch: {
      listtenTimeChange() {
        if(!!this.contract.start_at) {
          this.timeError = false
          this.timeErrorMessage = ''
        } else {
          this.timeError = true
          this.timeErrorMessage = I18n.t('start_at_empty', { scope: 'simple_form.labels.contract.error_message' })
          return
        }

        if(!this.contract.end_at) return
        const start_at = dayjs(this.contract.start_at)
        const end_at = dayjs(this.contract.end_at)
        if(start_at > end_at) {
          this.timeError = true
          this.timeErrorMessage = I18n.t('start_at_greater_than_end_at', { scope: 'simple_form.labels.contract.error_message' })
        } else {
          this.timeError = false
          this.timeErrorMessage = ''
        }
      },
    },
    mounted() {
      if(!this.contract.start_at) {
        this.timeError = true
        this.timeErrorMessage = I18n.t('start_at_empty', { scope: 'simple_form.labels.contract.error_message' })
      } else {
        this.timeError = false
      }
    }
  }
</script>

<style>
.form-group > .flatpickr-input {
  background: white;
}
</style>
