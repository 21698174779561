<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="company_account_company">{{ I18n.t('activerecord.models.company') }}</label>
          <ValidationProvider rules="required" :name="I18n.t('activerecord.models.company')" v-slot="{ errors }" immediate>
            <input type="hidden" name="company_account[company_id]" v-model="company_account.company_id">
            <multiselect v-model="company_account.company" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" :allow-empty="false" label="name" track-by="id" @input='setCompanyId(company_account.company)'>
              <template slot="noOptions">
                {{ I18n.t('list_empty') }}
              </template>
            </multiselect>
            <span class="block text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <small class="text-muted d-block" v-if="!manual_master_account">{{ I18n.t('manual_master_account', validates_message_scope) }}</small>
          <label for="company_account_account_type">{{ I18n.t('account_type', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('account_type', locale_scope)" v-slot="{ errors }" immediate>
            <select id="company_account_account_type" class="custom-select" name='company_account[account_type]' v-model="company_account.account_type">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(account_type, index) in account_types" :value="account_type" :key="index">{{ account_type }}</option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label for="company_account_uid">{{ I18n.t('account_uid', locale_scope) }}</label>
          <small class="text-muted d-block">長度為 16，全數字</small>
          <ValidationProvider :rules="{ required: true, regex: /^\d{16}$/ }" :name="I18n.t('account_uid', locale_scope)" v-slot="{ errors }" immediate>
            <input type="text" id="company_account_uid" class="form-control " name='company_account[account_uid]' v-model.trim="company_account.account_uid" />
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label for="company_account_status">{{ I18n.t('status', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('status', locale_scope)" v-slot="{ errors }" immediate>
            <select id="company_account_status" class="custom-select" name='company_account[status]' v-model="company_account.status">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(status, index) in statuses" :value="status" :key="index">{{ I18n.t(`simple_form.labels.company_account.status.${status}`) }}</option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <input name="company_account[poc]" type="hidden" value="0">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" id="company_account_poc" name="company_account[poc]" value="1" class="custom-control-input" v-model="company_account.poc">
            <label for="company_account_poc" class="custom-control-label">{{ I18n.t('poc', locale_scope) }}</label>
          </div>
        </div>
      </div>
    </div>

    <comment-form :commentable="'company_account'" :comments="comments" :comment_categories="comment_categories"></comment-form>
  </div>
</template>

<script>
  export default {
    props: {
      company_account_form: {
        type: Object,
        requred: true
      },
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.company_account'
        },
        validates_message_scope: {
          scope: 'activerecord.errors.messages'
        }
        ,
        company_account: this.company_account_form.company_account,
        company: this.company_account_form.company_account.company,
        companies: this.company_account_form.companies,
        account_types: this.company_account_form.account_types,
        comments: this.company_account_form.company_account.comments,
        comment_categories: this.company_account_form.comment_categories,
        manual_master_account: this.company_account_form.manual_master_account,
        statuses: this.company_account_form.statuses
      }
    },
    methods: {
      setCompanyId(company) {
        this.company_account.company_id = company.id
      },
    },
  }
</script>
