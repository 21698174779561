<template>
  <div class="mb-3 card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span>{{ I18n.t('activerecord.models.comment') }}</span>
      <button type="button" class="btn btn-primary" @click.prevent="newComment">
        <font-awesome-icon :icon="['fas', 'plus']" />
      </button>
    </div>
    <div class="card-body" v-if="selected_comments.length > 0">
      <div class="form-group" v-for="(selected_comment, selected_comment_index) in selected_comments" :key="selected_comment_index" v-show="!isDeleted(selected_comment)">
        <input type="hidden" v-model="selected_comment.id" :name="`${commentable}[comments_attributes][${selected_comment_index}][id]`">
        <input type="hidden" v-model="selected_comment._destroy" :name="`${commentable}[comments_attributes][${selected_comment_index}][_destroy]`">
        <div class="d-flex justify-content-between" >
          <div class="flex-shrink-0">
            <select v-model="selected_comment.category" :name="`${commentable}[comments_attributes][${selected_comment_index}][category]`" class="custom-select">
              <option v-for="(comment_category, comment_category_index) in comment_categories" :key="comment_category_index" :value="comment_category">
                {{ I18n.t(`simple_form.labels.comment.category.${comment_category}`)}}
              </option>
            </select>
          </div>
          <ValidationProvider tag="div" class="w-100" rules="required" :name="I18n.t('content', locale_scope)" v-slot="{ errors }" immediate>
            <input type="text" class="form-control" v-model.trim="selected_comment.content" :name="`${commentable}[comments_attributes][${selected_comment_index}][content]`">
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="flex-shrink-0">
            <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteComment(selected_comment_index)">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      commentable: {
        type: String,
        required: true
      },
      comments: {
        type: Array,
        required: true
      },
      comment_categories: {
        type: Array,
        required: true
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.comment'
        },
        selected_comments: this.comments,
      }
    },
    methods: {
      newComment() {
        const new_comment = [{
          id: null,
          category: 'common',
          content: '',
          _destroy: null
        }]
        this.selected_comments = [
          ...this.selected_comments, ...new_comment
        ]
      },
      deleteComment(index) {
        this.selected_comments[index]._destroy = '-1'
      },
      isDeleted(selected_comment){
        return selected_comment._destroy == '-1'
      }
    }
  }
</script>
