<template>
  <div class="col-12 col-sm-4">
    <div class="form-group">
      <label>{{ I18n.t('tax_code', locale_scope) }}</label>
      <ValidationProvider
        :rules="{ required: true }"
        :name="I18n.t('tax_code', locale_scope)"
        v-slot="{ errors }"
        immediate
      >
        <div class="input-group">
          <input type="hidden" name='company[tax_code]' v-model="company.tax_code">
          <multiselect
            v-model.trim="company.tax_code"
            :options="tax_code_options"
            :placeholder="I18n.t('helpers.select.prompt')"
            :show-labels="false"
            :allow-empty="false"
          >
            <template slot="noOptions">
              {{ I18n.t('list_empty') }}
            </template>
          </multiselect>
        </div>
        <span class="text-danger">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      company: {
        type: Object
      },
      tax_code_options: {
        type: Array
      },
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.company'
        },
      }
    }
  }
</script>
