<template>
  <span class='badge badge-pill float-right' :class="cssClass()">{{ I18n.t(`simple_form.labels.billing_task.status.${this.task_status}`) }}</span>
</template>

<script>
  export default {
    props: {
      task_status:{
        type: String,
        requred: true
      }
    },
    methods: {
      cssClass() {
        const cssClassTable = {
          pending: 'badge-warning',
          processing: 'badge-primary',
          done: 'badge-success',
          fail: 'badge-danger',
        }
        return cssClassTable[this.task_status]
      },
    }
  }
</script>
