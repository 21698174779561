<template>
  <div class="mx-3">
    <table class="table table-striped table-hover w-100 text-center align-middle">
      <thead>
        <tr>
          <th>檔案名稱</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(file, fileIndex) in upload_files_list" :key="fileIndex">
          <td>{{ file }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      upload_files_list: {
        type: Array,
        requred: true
      },
    }
  }
</script>
