<template>
  <div>
    <!-- 選擇發信公司 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('activerecord.models.company') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <ValidationProvider rules="required" :name="I18n.t('activerecord.models.company')" v-slot="{ errors }" immediate>
          <multiselect
            v-model="company"
            :options="companies"
            :placeholder="I18n.t('helpers.select.prompt')"
            :allow-empty="false"
            label="name"
            track-by="id"
          >
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <!-- 相關資訊 -->
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info', email_function_locale) }}
      </div>
      <div class="card-body">
        <!-- Netsuite ID -->
        <div class="form-group">
          <label for="netsuite_id">{{ I18n.t('netsuite_id', company_locale) }}</label>
          <div class="form-control">{{ netsuite_id }}</div>
        </div>

        <!-- 虛擬帳號 -->
        <div class="form-group">
          <label for="virtual_account_number">{{ I18n.t('virtual_account_number', net_suite_setting_locale) }}</label>
          <div class="form-control">{{ virtual_account_number }}</div>
        </div>

        <!-- 虛擬帳號戶名 -->
        <div class="form-group">
          <label for="netsuite_subsidiary">{{ I18n.t('netsuite_subsidiary', net_suite_setting_locale) }}</label>
          <div class="form-control">{{ netsuite_subsidiary }}</div>
        </div>

        <!-- 收件者 -->
        <div class="form-group">
          <label for="main_email">{{ I18n.t('main', company_account_locale) }}</label>
          <div class="form-control">
            <div v-for="main_email in main_emails" :key="main_email" class="badge badge-primary mr-1 d-inline">{{ main_email }}</div>
          </div>
        </div>

        <!-- 副本收件者 -->
        <div class="form-group">
          <label for="cc_email">{{ I18n.t('cc_receiver', company_account_locale) }}</label>
          <div class="form-control">
            <div v-for="cc_email in cc_emails" :key="cc_email" class="badge badge-secondary mr-1 d-inline">{{ cc_email }}</div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success" @click.prevent="sendVirtualAccountNotification">
      {{ I18n.t('send_email', email_function_locale) }}
    </button>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from "sweetalert2";

  export default {
    props: {
      companies: {
        type: Array
      },
      selected_company_id: {
        type: String
      }
    },
    data() {
      return {
        email_function_locale: {
          scope:'admins.email_functions.show.refresh_virtual_account_and_notify'
        },
        company_locale: {
          scope:'activerecord.attributes.company'
        },
        company_account_locale: {
          scope: 'simple_form.labels.company_contact.category'
        },
        net_suite_setting_locale: {
          scope: 'activerecord.attributes.net_suite_setting'
        },
        company: null,
        netsuite_id: null,
        main_emails: null,
        cc_emails: null,
        virtual_account_number: null,
        netsuite_subsidiary: null,
        window: window,
        sendEmailUrl: `${window.location.pathname}/send_email`,
        indexUrl: window.location.pathname.split('/').slice(0, -1).join('/')
      }
    },
    mounted() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = this.authenticity_token

      if (this.selected_company_id) {
        this.company = this.companies.find(company => company.id == this.selected_company_id)
      }
    },
    watch: {
      'company': function(company) {
        // Javascript ?. => ruby &.
        this.select_company_id = company?.id
        this.netsuite_id = company?.netsuite_id
        this.main_emails = company?.grouped_contact_emails?.main
        this.cc_emails = company?.grouped_contact_emails?.cc
        this.virtual_account_number = company?.net_suite_setting?.virtual_account_number
        this.netsuite_subsidiary = company?.net_suite_setting?.netsuite_subsidiary
      }
    },
    methods: {
      sendVirtualAccountNotification() {
        const data = { company_id: this.select_company_id }

        // 轉圈圈動畫
        this.showAlert()

        axios.post(this.sendEmailUrl, data
        ).then(({ data }) => {
          // 關閉動畫，顯示 response 訊息
          const type = data.type
          const message = data.message
          if (data.virtual_account_number) {
            this.virtual_account_number = data.virtual_account_number
          }
          if (data.netsuite_subsidiary) {
            this.netsuite_subsidiary = data.netsuite_subsidiary
          }
          Toastr(type, message)
        }).catch((error) => {
          Toastr('error', error)
        }).finally(() => {
          Swal.close()
        })
      },
      showAlert() {
        Swal.fire({
          title: '資料更新中，請稍候',
          html: '<div class="lds-ring">\
                  <div></div>\
                  <div></div>\
                  <div></div>\
                  <div></div>\
                </div>',
          allowEscapeKey: false,
          allowEnterKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        })
      }
    }
  }
</script>
