<template>
  <div class="mb-3 card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span>{{ I18n.t('activerecord.models.company_contact') }}</span>
      <button type="button" class="btn btn-primary" @click.prevent="newCompanyContact">
        <font-awesome-icon :icon="['fas', 'plus']" />
      </button>
    </div>
    <div class="card-body">
      <div class="form-group" v-for="(selected_contact, selected_contact_index) in selected_contacts" :key="selected_contact_index" v-show="!isDeleted(selected_contact)">
        <input type="hidden" v-model="selected_contact.id" :name="`company[company_contacts_attributes][${selected_contact_index}][id]`">
        <input type="hidden" v-model="selected_contact._destroy" :name="`company[company_contacts_attributes][${selected_contact_index}][_destroy]`">
        <!-- <label>{{ I18n.t('email', locale_scope) }}</label> -->
        <div class="d-flex justify-content-between" >
          <div class="flex-shrink-0">
            <select v-model="selected_contact.category" :name="`company[company_contacts_attributes][${selected_contact_index}][category]`" class="custom-select">
              <option v-for="(contact_category, contact_category_index) in contact_categories" :key="contact_category_index" :value="contact_category">
                {{ I18n.t(`simple_form.labels.company_contact.category.${contact_category}`)}}
              </option>
            </select>
          </div>
          <ValidationProvider tag="div" class="w-100" rules="required|email" :name="I18n.t('email', locale_scope)" v-slot="{ errors }" immediate>
          <input type="email" class="form-control form-input" v-model.trim="selected_contact.email" :name="`company[company_contacts_attributes][${selected_contact_index}][email]`">
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
          <div class="flex-shrink-0">
            <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteContact(selected_contact_index)">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    contact_categories: {
      type: Array,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    blank_company_contact: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.company_contact'
      },
      selected_contacts: this.company.company_contacts,
    }
  },
  methods: {
    newCompanyContact() {
      const new_contact = [{...this.blank_company_contact}]
      this.selected_contacts = [
        ...this.selected_contacts, ...new_contact
      ]
    },
    deleteContact(index) {
      this.selected_contacts[index]._destroy = '-1'
    },
    isDeleted(selected_contact){
      return selected_contact._destroy == '-1'
    }
  }
}
</script>
