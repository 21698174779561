<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.company_account') }}
    </div>
    <div class="card-body pb-0">
      <div class="form-group">
        <template v-if="selected_accounts.length > 0">
          <input type="hidden" name='company[company_account_ids][]' v-for="(selected_account, index) in selected_accounts" :key="index" :value="selected_account.id">
        </template>
        <template v-else>
          <input type="hidden" name='company[company_account_ids][]'>
        </template>
        <multiselect
          v-model="selected_accounts"
          :options="selectable_accounts"
          :placeholder="I18n.t('helpers.select.prompt')"
          :show-labels="false"
          :multiple="true"
          selectedLabel="true"
          label="account_uid"
          track-by="id"
        >
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      company_accounts: {
        type: Array,
        required: true
      },
      company: {
        type: Object,
        required: true
      }
    },
    data(){
      return{
        selected_accounts: this.company.company_accounts,
        selectable_accounts: this.company_accounts.concat(this.company.company_accounts)
      }
    }
  }
</script>
