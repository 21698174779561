<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "0", "asc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            link
          </th>
          <th scope="col" data-name="category" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('category', locale_scope) }}
          </th>
          <th scope="col" data-name="service_name" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('activerecord.models.product') }}
          </th>
          <th scope="col" data-name="region" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('activerecord.models.region') }}
          </th>
          <th scope="col" data-sortable="false" data-class-name="text-left align-middle" class='text-center'>
            {{ I18n.t('admins.commit_groups.index.interval') }}
          </th>
          <th scope="col" data-name="status" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
            {{ I18n.t('status', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center' v-if="editable">
            {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
    props: {
      url: {
        type: String,
        required: true
      },
      editable: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.commit_group'
        },
        dataTable: null,
      }
    },
    mounted() {
        this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            responsive: true,
            ajax: {
              url: this.url,
            },
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            },
          }).api().columns.adjust();
    },
  };
</script>
