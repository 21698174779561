<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100 text-center" data-order="">
      <thead>
        <tr>
          <th scope="col" data-name="function" data-sortable="false" data-class-name="align-middle">
              {{ I18n.t('function', locale_scope) }}
          </th>
          <th scope="col" data-name="description" data-sortable="false" data-class-name="align-middle">
              {{ I18n.t('description', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="align-middle">
              {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
  export default {
    props: {
      url: {
        type: String
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.email_function'
        },
        dataTable: null,
      }
    },
    mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
            reponsive: true,
            ajax: {
              url: this.url
            },
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`
            },
        }).api().columns.adjust();
    },
    methods: {
      reloadTable() {
        this.dataTable.ajax.reload()
      }
    }
  }
</script>
