<template>
  <div id='event-title'>
    <ol class="mb-0 px-md-4 breadcrumb mx-md-n4">
      <li class="breadcrumb-item">
        <a href="/billing_events">{{ I18n.t('activerecord.models.billing_event') }}</a>
      </li>
      <li class="breadcrumb-item active">{{ year_month }}</li>
    </ol>
    <div class='border-bottom mb-3 pb-3'>
      <div class="flex-wrap pt-3 pb-2 mb-3 d-flex justify-content-between flex-md-nowrap align-items-cente">
        <h2>{{ `${I18n.t('activerecord.models.billing_event')} ${year_month}` }}</h2>
      </div>
      <div id='task-tag-group'>
        <span class='task-tag' :class='compare_rawdata_done ? "task-done" : "task-processing"'>上傳 rawdata 確認檔案</span>
        <span class="arrow" :class='compare_rawdata_done ? "arrow-done" : "arrow-processing"'></span>
        <span class='arrow arrow-back'></span>
        <span class='task-tag' :class='import_rawdata_done ? "task-done" : "task-processing"'>匯入 rawdata</span>
        <span class="arrow" :class='import_rawdata_done ? "arrow-done" : "arrow-processing"'></span>
        <span class='arrow arrow-back'></span>
        <span class='task-tag' :class='manual_tasks_done ? "task-done" : "task-processing"'>人工處理帳戶</span>
        <span class="arrow" :class='manual_tasks_done ? "arrow-done" : "arrow-processing"'></span>
        <span class='arrow arrow-back'></span>
        <span class='task-tag' :class='billing_done ? "task-done" : "task-processing"'>出帳檔案</span>
        <span class="arrow" :class='billing_done ? "arrow-done" : "arrow-processing"'></span>
        <span class='arrow arrow-back'></span>
        <span class='task-tag' :class='formal_mail_done ? "task-done" : "task-processing"'>測試信</span>
        <span class="arrow" :class='formal_mail_done ? "arrow-done" : "arrow-processing"'></span>
        <span class='arrow arrow-back'></span>
        <span class='task-tag' :class='formal_mail_done ? "task-done" : "task-processing"'>對帳信</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      year_month: {
        type: String,
        requred: true
      },
      compare_rawdata_done: {
        type: Boolean,
        requred: true
      },
      import_rawdata_done: {
        type: Boolean,
        requred: true
      },
      manual_tasks_done: {
        type: Boolean,
        requred: true
      },
      billing_done: {
        type: Boolean,
        requred: true
      },
      formal_mail_done: {
        type: Boolean,
        requred: true
      }
    },
    mounted() {
      const height = document.querySelector('nav').clientHeight
      document.querySelector('#event-title').style.top=`${height}px`

      window.addEventListener('resize', () => {
        const height = document.querySelector('nav').clientHeight
        document.querySelector('#event-title').style.top=`${height}px`
      })
    }
  }
</script>

<style>
  #event-title {
    background: rgb(250 250 250);
    position: sticky;
    z-index: 9999;
  }
  #task-tag-group {
    font-size: 0;
  }
  .task-tag {
    margin: 0;
    padding: 0;
    display: inline-block;
    width: calc(100%/6);
    border-width: 3px 0px 3px 0px;
    border-style: solid;
    border-color: #CED4DA;
    font-size: 1rem;
    text-align: center;
    height: 3rem;
    line-height: calc(3rem - 3px);
  }
  .task-tag:nth-child(1) {
    border-left-width: 3px;
    border-radius: 50px 0 0 50px;
  }
  .task-tag:nth-last-child(1) {
    border-right-width: 3px;
    border-radius: 0 50px 50px 0;
  }
  .task-processing {
    background: white;
    color: black;
  }
  .task-done {
    background: rgb(230, 230, 230);
    color: #6c757d;
  }
  .arrow {
    margin: 0.2rem 0 0.2rem -1px;
    padding: 0;
    display: inline-block;
    clip-path: polygon(0% 0%, 0% 100%, 50% 50%);
    height: 2.6rem;
    width: 2.6rem;
    position: absolute;
    z-index: 2;
  }
  .arrow-back {
    margin: 0;
    height: 3rem;
    width: 3rem;
    background: #CED4DA;
    position: absolute;
    z-index: 1;
  }
  .arrow-processing {
    background: white;
  }
  .arrow-done {
    background: rgb(230, 230, 230);
  }
</style>
