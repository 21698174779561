<template>
  <div>
    <div class="mb-2">
      <div class="d-flex">
        <div class="mr-2 badge badge-primary">一般</div>
        <div class="mr-2 badge badge-warning"> {{ I18n.t('activerecord.attributes.company_account.poc') }}</div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover w-100" data-order='[[ "2", "desc" ]]' >
        <thead>
          <tr>
            <th scope="col" data-name="company_id" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('activerecord.models.company') }}
            </th>
            <th scope="col" data-name="account_id" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('account_uid', locale_scope) }}
            </th>
            <th scope="col" data-name="status" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('status', locale_scope) }}
            </th>
            <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
                {{ I18n.t('action') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
export default {
    props: {
      url: {
        type: String,
        required: true
      },
      ids: {
        type: Array
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.company_account'
        },
        dataTable: null,
      }
    },
    mounted() {
        this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            responsive: true,
            ajax: {
              url: this.url,
              data: (d) => {
                d.id = this.ids
              },
            },
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            },
          }).api().columns.adjust();
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
      },
    },
  };
</script>

<style scoped>
  tr > th {
    width: 20%;
  }

  tr > th:nth-child(1) {
    width: 40%;
  }

  tr > th:nth-child(2) {
    width: 25%;
  }

  tr > td {
    width: 20%;
  }

  tr > td:nth-child(1) {
    width: 40%;
    word-wrap: break-word;
  }

  tr > td:nth-child(2) {
    width: 25%;
  }
</style>
