<template>
  <div class="mb-3 card">
    <div class="card-header">
      <input name="contract[marketplace_mark]" type="hidden" value="0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="contract_marketplace_mark" name="contract[marketplace_mark]" v-model="contract.marketplace_mark">
        <label class="custom-control-label"  for="contract_marketplace_mark">{{ I18n.t('marketplace_mark', locale_scope) }}</label>
      </div>
    </div>
    <div class="card-body" v-if='contract.marketplace_mark'>
      <input type="hidden" name="contract[marketplace_discount_rate]" v-model="contract.marketplace_discount_rate">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="contract_marketplace_discount_rate">{{ I18n.t('marketplace_discount_rate', locale_scope) }}</label>
            <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 100 }" :name="I18n.t('marketplace_discount_rate', locale_scope)" v-slot="{ errors }" immediate>
              <div class="input-group">
                  <input type="text" id="contract_marketplace_discount_rate" class="form-control" name='contract[marketplace_discount_rate]' v-model="contract.marketplace_discount_rate">
                  <div class="input-group-append">
                    <span class="input-group-text">％</span>
                  </div>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
              <small class="text-muted">(100 - {{ contract.marketplace_discount_rate }}) = {{ 100 - Number(contract.marketplace_discount_rate) }}%</small>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      contract: {
        type: Object
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
      }
    },
    computed: {
      listtenMarketplace() {
        return {
          marketplace_mark: this.contract.marketplace_mark,
          marketplace_discount_rate: this.contract.marketplace_discount_rate,
        }
      }
    },
    watch: {
      listtenMarketplace() {
        if(!this.contract.marketplace_mark) { this.contract.marketplace_discount_rate = 0 }
      },
    },
  }
</script>
