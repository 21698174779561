<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">{{ I18n.t('info') }}</div>
      <div class="card-body">
        <ValidationProvider :rules="{ required: true, regex: /^[^\\\/]+$/ }" :name="I18n.t('name', locale_scope)" v-slot="{ errors }" immediate>
          <div class="form-group">
            <label for="addon_fee_name">{{ I18n.t('name', locale_scope) }}</label>
            <small class="text-muted">請勿使用如"/"、"\"等特殊符號</small>
            <input type="text" id="addon_fee_name" class="form-control" name='addon_fee[name]' v-model="addon_fee.name">
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <ValidationProvider :rules="{ required: true }" :name="I18n.t('category', locale_scope)" v-slot="{ errors }" immediate>
          <div class="form-group">
            <label for="addon_fee_category">{{ I18n.t('category', locale_scope) }}</label>
            <select v-model="addon_fee.category" name="addon_fee[category]" class="custom-select">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(category, category_index) in categories" :value="category" :key="category_index">
                {{ I18n.t(`simple_form.options.addon_fee.category.${category}`)}}
              </option>
            </select>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>

        <div class="form-group">
          <label for="addon_fee_charge">{{ I18n.t('charge', locale_scope) }}</label>
          <div class="row no-gutters">
            <div class="col-auto">
              <select class="custom-select" id="addon_fee_charge_type" v-model="addon_fee.charge_type" name="addon_fee[charge_type]">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(charge_type, charge_type_index) in charge_types" :value="charge_type" :key="charge_type_index">
                  {{ I18n.t(`simple_form.options.addon_fee.charge_type.${charge_type}`) }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <select class="custom-select" id="addon_fee_currency" v-model="addon_fee.currency" name="addon_fee[currency]">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(currency, currency_index) in selectable_currencies()" :value="currency" :key="currency_index">
                  {{ I18n.t(`simple_form.options.addon_fee.currency.${currency}`) }}
                </option>
              </select>
            </div>
            <div class="col-12 col-sm">
              <input type="text" id="addon_fee_charge" class="form-control" name='addon_fee[charge]' inputmode="decimal" :placeholder="I18n.t('charge', locale_scope)" required v-model="addon_fee.charge">
              <span class="text-danger" v-if="chargeError" >{{ chargeErrorMessage }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="addon_fee_start_at">{{ I18n.t('start_at', locale_scope) }}</label>
              <small class="text-muted">若為一次性費用，{{ I18n.t('start_at', locale_scope) }}與{{ I18n.t('end_at', locale_scope) }}設定相同值即可</small>
              <month-picker :name="'addon_fee[start_at]'" :current_date="addon_fee.start_at" @changeDate="setStartAt"></month-picker>
              <span class="text-danger" v-if="durationError || !addon_fee.start_at" >{{ durationErrorMessage || I18n.t('start_at.empty', this.locale_error_scope) }}</span>
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="addon_fee_end_at">{{ I18n.t('end_at', locale_scope) }}</label>
              <month-picker :name="'addon_fee[end_at]'" :current_date="addon_fee.end_at" @changeDate="setEndAt"></month-picker>
              <span class="text-danger" v-if="!addon_fee.end_at" >{{ I18n.t('end_at.empty', this.locale_error_scope) }}</span>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="addon_fee_description">{{ I18n.t('description', locale_scope) }}</label>
          <input type="text" id="addon_fee_description" class="form-control" name='addon_fee[description]' v-model="addon_fee.description">
        </div>
      </div>
    </div>

    <comment-form :commentable="'addon_fee'" :comments="comments" :comment_categories="comment_categories"></comment-form>
  </div>
</template>

<script>
  export default {
    props: {
      addon_fee_form: {
        type: Object,
        requred: true
      },
    },
    data() {
      return {
        locale_scope: { scope: 'activerecord.attributes.addon_fee' },
        locale_error_scope: { scope: 'simple_form.errors.addon_fee' },
        addon_fee: this.addon_fee_form.addon_fee,
        categories: this.addon_fee_form.categories,
        charge_types: this.addon_fee_form.charge_types,
        comments: this.addon_fee_form.addon_fee.comments,
        comment_categories: this.addon_fee_form.comment_categories,
        chargeError: false,
        chargeErrorMessage: null,
        durationError: false,
        durationErrorMessage: null,
      }
    },
    methods: {
      selectable_currencies(){
        return lodash(['usd', this.addon_fee.currency]).uniq().compact().value()
      },
      setStartAt(start_at) {
        this.addon_fee.start_at = start_at
      },
      setEndAt(end_at) {
        this.addon_fee.end_at = end_at
      },
    },
    computed: {
      listtenDuration() {
        return {
          start_at: this.addon_fee.start_at,
          end_at: this.addon_fee.end_at,
        }
      }
    },
    watch: {
      'addon_fee.charge': function(charge) {
        if(charge === '') {
          this.chargeError = true
          this.chargeErrorMessage = I18n.t('charge.empty', this.locale_error_scope)
          return
        }

        // integer ok, float ok, 10.01.01 not ok, 01.01 not ok
        if(!charge.match(/^(0|[1-9]\d*)(\.\d+)?$/)) {
          this.chargeError = true
          this.chargeErrorMessage = I18n.t('charge.invalid_format', this.locale_error_scope)
          return
        }

        if(charge <= 0) {
          this.chargeError = true
          this.chargeErrorMessage = I18n.t('charge.less_than_zero', this.locale_error_scope)
          return
        }

        this.chargeError = false
        this.chargeErrorMessage = null
      },
      listtenDuration(current) {
        // 都開始與結束時間都填才去判斷
        if(!current.start_at || !current.end_at) return

        const start_at = dayjs(current.start_at)
        const end_at = dayjs(current.end_at)

        if(start_at > end_at) {
          this.durationError = true
          this.durationErrorMessage = I18n.t('start_at.less_than_end_at', this.locale_error_scope)
          return
        }
        this.durationError = false
        this.durationErrorMessage = null
      }
    },
    mounted() {
      if(this.addon_fee.charge <= 0) {
        this.chargeError = true
        this.chargeErrorMessage = I18n.t('charge.less_than_zero', this.locale_error_scope)
        return
      }
    },
  }
</script>
