<template>
  <div class="mb-3 card">
    <div class="card-header">{{ I18n.t('discount', locale_scope) }}</div>
    <div class="card-body col-12">
      <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 100 }" :name="I18n.t('client_discount_rate', locale_scope)" v-slot="{ errors }" immediate>
        <div class="form-group">
          <label for="contract_client_discount_rate">{{ I18n.t('client_discount_rate', locale_scope) }}</label>
          <small class="text-muted">(100 - {{ contract.client_discount_rate }}) = {{ 100 - Number(contract.client_discount_rate) }}%</small>
          <div class="input-group">
            <input type="text" id="contract_client_discount_rate" class="form-control" name='contract[client_discount_rate]' v-model="contract.client_discount_rate">
            <div class="input-group-append">
              <span class="input-group-text">％</span>
            </div>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider :rules="{ required: true, min_value: 0, max_value: 100 }" :name="I18n.t('other_service_discount', locale_scope)" v-slot="{ errors }" immediate>
        <div class="form-group" v-if="service_commit_group_enable">
          <label for="contract_other_service_discount">{{ I18n.t('other_service_discount', locale_scope) }}</label>
          <small class="text-muted">(100 - {{ contract.other_service_discount }}) = {{ 100 - Number(contract.other_service_discount) }}%</small>
          <small class="text-primary ml-3">{{ I18n.t('other_service_discount_hint', locale_scope) }}</small>
          <div class="input-group">
            <input type="text" id="contract_other_service_discount" class="form-control" name='contract[other_service_discount]' v-model="contract.other_service_discount">
            <div class="input-group-append">
              <span class="input-group-text">％</span>
            </div>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      contract: {
        type: Object
      },
      service_commit_group_enable: {
        type: Boolean
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
      }
    }
  }
</script>
