<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.company_account') }}
    </div>

    <div class="card-body pb-0">
      <div class="form-group">
        <input name="contract[manual_processing]" type="hidden" value="0">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" id="contract_manual_processing" name="contract[manual_processing]" value="1" class="custom-control-input" v-model="contract.manual_processing">
          <label for="contract_manual_processing" class="custom-control-label">{{ I18n.t('manual_processing', locale_scope) }}</label>
          <small class='text-primary'>出帳時，人工處理帳戶預設選項</small>
        </div>
      </div>

      <div class="form-group">
        <template v-if="selected_accounts.length > 0">
          <input type="hidden" name='company_account_ids[]' v-for="(selected_account, index) in selected_accounts" :key="index" :value="selected_account.id">
        </template>
        <template v-else>
          <input type="hidden" name='company_account_ids[]'>
        </template>
        <multiselect
          v-model="selected_accounts"
          :options="selectable_accounts"
          :placeholder="I18n.t('helpers.select.prompt')"
          label="account_uid"
          track-by="id"
          :show-labels="false"
          :multiple="true"
        >
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      company_accounts: {
        type: Array,
        required: true
      },
      contract: {
        type: Object,
        required: true
      }
    },
    data(){
      return{
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
        selected_accounts: this.contract.company_accounts,
        selectable_accounts: this.company_accounts.concat(this.contract.company_accounts)
      }
    }
  }
</script>
