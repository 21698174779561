<template>
  <div>
    <event-title
      :year_month='billing_event.year_month'
      :compare_rawdata_done='compare_rawdata.status === "done"'
      :import_rawdata_done='import_rawdata.status === "done"'
      :manual_tasks_done='get_accounts_info.status === "done" && adjust_account_data.status === "done"'
      :billing_done='billing.status === "done"'
      :formal_mail_done='formal_mail.status === "done"'
    >
    </event-title>

    <!-- 下方是 rawdata 上傳區 -->
    <div class="mb-5 card" :key="rawdataKey">
      <div class="card-header card-title">
        <span>{{ I18n.t('upload_rawdata_title', locale_scope) }}</span>
        <small
          v-if='uploadRawdataFiles.length > 0'
          class="text-primary"
        >
          {{ I18n.t('files_uploaded', locale_scope) }}
        </small>
        <small
          v-if="compare_rawdata.status !== 'done'"
          class="text-danger"
        >
          {{ I18n.t('time_evaluation_of_rawdata_comparison', locale_scope) }}
        </small>
        <billing-task-status-tag :task_status='compare_rawdata.status'></billing-task-status-tag>
      </div>
      <div class='card-body' v-if='billing_event.status !== "done"'>
        <div class="uppy-area-wrapper uppy-height">
          <div id="upload-rawdata"></div>
          <div v-if='uploadRawdataFiles.length > 0'>
            <upload-files-list :upload_files_list='uploadRawdataFiles'></upload-files-list>
            <button
              v-if="compare_rawdata.status === 'pending' && uploadRawdataFiles.length > 0"
              @click="reuploadRawdata"
              class="d-block mx-auto btn btn-warning"
              type="button"
            >
              {{ I18n.t('reupload_rawdata', locale_scope) }}
            </button>
          </div>
        </div>
        <button
          :disabled='uploadRawdataError || uploadRawdataFiles.length !== 2 || compare_rawdata.status !== "pending"'
          @click="startCompareRawdata"
          class="d-block mb-3 mx-auto btn btn-success w-25"
          type="button"
        >
          {{ I18n.t('start_check_rawdata', locale_scope) }}
        </button>
        <div class="p-3 mx-3 rounded d-flex align-items-center msg-block">
          <span>{{ I18n.t('download_link_notice', locale_scope) }}</span>
          <a
            v-if="compare_rawdata.status === 'done'"
            :href="`${this.current_path}/download_task_files?category=${compare_rawdata.category}`"
            target="_blank"
          >
            {{ compare_rawdata.billing_data_folder.folder }}
          </a>
          <button
            v-if="['done', 'fail'].includes(compare_rawdata.status) && import_rawdata.status === 'pending'"
            :disabled='billingDone'
            @click="resetCompareRawdataTask"
            class="btn btn-warning ml-auto"
            type="button"
          >
            {{ I18n.t('reset_rawdata_comparison', locale_scope) }}
          </button>
        </div>
        <small
          class="text-danger mx-3 mb-3"
        >
          {{ compare_rawdata.status === 'done' ? I18n.t('download_and_check_content', locale_scope) : I18n.t('time_notice_of_rawdata_comparision', locale_scope) }}
        </small>
        <div
          v-if="compare_rawdata.status === 'fail'"
          class="p-3 mx-3 mb-3 text-danger msg-block"
        >
          {{ I18n.t('error_message_notice', locale_scope) + compare_rawdata.error_message }}
        </div>
      </div>
    </div>
    <!-- 上方是 rawdata 上傳區 -->

    <!-- 下方是 rawdata 匯入區 -->
    <div class="mb-5 card">
      <div class="card-header card-title">
        <span>{{ I18n.t('import_rawdata_title', locale_scope) }}</span>
        <small
          v-if="import_rawdata.status !== 'done'"
          class="text-danger"
        >
          {{ I18n.t('time_evaluation_of_import_rawdata', locale_scope) }}
        </small>
        <billing-task-status-tag :task_status='import_rawdata.status'></billing-task-status-tag>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(handler, handlerIndex) in import_rawdata.billing_handlers"
            :key="handlerIndex"
            class="mb-3 col-4"
          >
            <div class="mx-3">
              <button
                @click="approveImportRawdata(handler)"
                :disabled='handler.approved'
                :class="`btn btn-block border-success ${ handler.approved ? 'btn-success' : 'text-success' }`"
                type="button"
              >
                {{ handler.manager.full_name }}
              </button>
              <p class="text-center text-success card-text" v-if='handler.approved'>
                {{ I18n.t('confirm_time_notice', locale_scope) + handler.approved_at }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="import_rawdata.status === 'fail'"
        class="p-3 mx-3 mb-3 text-danger rounded d-flex align-items-center msg-block"
      >
        <span>{{ I18n.t('error_message_notice', locale_scope) + import_rawdata.error_message }}</span>
      </div>
    </div>
    <!-- 上方是 rawdata 匯入區 -->

    <div class="mb-5 card">
      <div class="card-header card-title d-flex justify-content-between align-items-center">
        <span> {{ I18n.t('manual_processing_accounts_title', locale_scope) }}</span>
        <button
          :disabled='import_rawdata.status != "done" ||get_accounts_info.status === "processing" || adjust_account_data.status === "processing"'
          @click="skipManualTasks"
          class="btn btn-success"
          type="button"
        >
          略過此步驟
        </button>
      </div>

      <!-- 下方是下載手動調整 accounts excel 區 -->
      <div class="card p-4">
        <div class="p-3 border card-header card-title">
          <span>{{ I18n.t('get_accounts_info_title', locale_scope) }}</span>
          <small
            v-if="get_accounts_info.status !== 'done'"
            class="text-danger"
          >
            {{ I18n.t('time_evaluation_of_get_accounts_info', locale_scope) }}
          </small>
          <billing-task-status-tag :task_status='get_accounts_info.status'></billing-task-status-tag>
        </div>
        <div v-if='billing_event.status !== "done"'>
          <div class="form-group p-3 border">
            <multiselect
              v-model="selectedAccounts"
              :options="companyAccounts"
              :placeholder="I18n.t('helpers.select.prompt')"
              :show-labels="false"
              :multiple="true"
              :taggable='true'
              @tag="addAccountUid"
              selectedLabel="true"
              track-by="account_uid"
              label="account_uid"
            >
            </multiselect>
            <button
              :disabled='import_rawdata.status !== "done" || get_accounts_info.status !== "pending"'
              @click="startGetAccountsInfo"
              class="d-block my-4 mx-auto d-block btn btn-success w-25"
              type="button"
            >
              {{ I18n.t('generate_accounts_excel', locale_scope) }}
            </button>
            <div class="p-3 rounded d-flex align-items-center msg-block">
              <span>{{ I18n.t('download_link_notice', locale_scope) }}</span>
              <!-- fail 也可下載是考慮到 task 在 data team 可能部分失敗，這樣可以下載到處理成功的檔案 -->
              <a
                v-if="get_accounts_info.status === 'done' || get_accounts_info.status === 'fail'"
                :href="`${this.current_path}/download_task_files?category=${get_accounts_info.category}`"
                target="_blank"
              >
                {{ get_accounts_info.billing_data_folder.folder }}
              </a>
              <button
                v-if="['done', 'fail'].includes(get_accounts_info.status) && billing.status === 'pending'"
                @click="redoGetAccountsInfoTask"
                class="btn btn-warning ml-auto"
                type="button"
              >
                {{ I18n.t('generate_next_batch_accounts_excel', locale_scope) }}
              </button>
            </div>
            <small class="text-danger">
              {{ get_accounts_info.status === 'done' ? I18n.t('download_and_check_content', locale_scope) : I18n.t('time_notice_of_get_accounts_info', locale_scope) }}
            </small>
            <div
              v-if="get_accounts_info.status === 'fail'"
              class="p-3 my-3 text-danger msg-block"
            >
              {{ I18n.t('error_message_notice', locale_scope) + get_accounts_info.error_message }}
            </div>
          </div>
        </div>
      </div>
      <!-- 上方是下載手動調整 accounts excel 區 -->


      <!-- 下方是上傳手動調整 accounts excel 區 -->

      <div class="card p-4" :key="accountsCsvKey">
        <div class="card-header card-title border">
          <span>{{ I18n.t('upload_accounts_excel_title', locale_scope) }}</span>
          <small
            v-if='uploadAccountsCsvFiles.length > 0'
            class="text-primary"
          >
            {{ I18n.t('files_uploaded', locale_scope) }}
          </small>
          <small
            v-if="adjust_account_data.status !== 'done'"
            class="text-danger"
          >
            {{ I18n.t('time_evaluation_of_adjust_accounts_info', locale_scope) }}
          </small>
          <billing-task-status-tag :task_status='adjust_account_data.status'></billing-task-status-tag>
        </div>
        <div v-if='billing_event.status !== "done"'>
          <div class="uppy-area-wrapper uppy-height border">
            <div id="upload-accounts-excel"></div>
            <div v-if='uploadAccountsCsvFiles.length > 0'>
              <upload-files-list :upload_files_list='uploadAccountsCsvFiles'></upload-files-list>
            </div>
          </div>
          <button
            :disabled='uploadAccountsCsvFiles.length <= 0 || uploadAccountsCsvFiles.length > 10 || adjust_account_data.status !== "pending"'
            @click="startImportAccountsExcel"
            class="d-block my-3 mx-auto btn btn-success w-25"
            type="button"
          >
            {{ I18n.t('start_adjust_accounts_excel', locale_scope) }}
          </button>
          <div class="p-3 rounded d-flex align-items-center msg-block">
            <span>{{ I18n.t('download_link_notice', locale_scope) }}</span>
            <!-- fail 也可下載是考慮到 task 在 data team 可能部分失敗，這樣可以下載到處理成功的檔案 -->
            <a
              v-if="adjust_account_data.status === 'done' || adjust_account_data.status === 'fail'"
              :href="`${this.current_path}/download_task_files?category=${adjust_account_data.category}`"
              target="_blank"
            >
              {{ adjust_account_data.billing_data_folder.folder }}
            </a>
            <button
              v-if="['done', 'fail'].includes(adjust_account_data.status) && billing.status === 'pending'"
              @click="redoAdjustAccountDataTask"
              class="btn btn-warning ml-auto"
              type="button"
            >
              {{ I18n.t('adjust_next_batch_accounts_excel', locale_scope) }}
            </button>
          </div>
          <small class="text-danger mb-3">
            {{ adjust_account_data.status === 'done' ? I18n.t('download_and_check_content', locale_scope) : I18n.t('time_notice_of_adjust_accounts_excel', locale_scope) }}
          </small>
          <div
            v-if="adjust_account_data.status === 'fail'"
            class="p-3 mx-3 mb-3 text-danger msg-block"
          >
            {{ I18n.t('error_message_notice', locale_scope) + adjust_account_data.error_message }}
          </div>
        </div>
      </div>

      <!-- 上方是上傳手動調整 accounts excel 區 -->
    </div>

    <!-- 下方是出帳檔案區 -->
    <div class="mb-5 card">
      <div class="card-header card-title">
        <span>{{ I18n.t('billing_title', locale_scope) }}</span>
        <small
          v-if="billing.status !== 'done'"
          class="text-danger"
        >
          {{ I18n.t('time_evaluation_of_billing', locale_scope) }}
        </small>
        <billing-task-status-tag :task_status='billing.status'></billing-task-status-tag>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(handler, handlerIndex) in billing.billing_handlers"
            :key="handlerIndex"
            class="mb-3 col-4"
          >
            <div class="mx-3">
              <button
                @click="approveBilling(handler)"
                :disabled='handler.approved'
                :class="`btn btn-block border-success ${ handler.approved ? 'btn-success' : 'text-success' }`"
                type="button"
              >
                {{ handler.manager.full_name }}
              </button>
              <p class="text-center text-success card-text" v-if='handler.approved'>
                {{ I18n.t('confirm_time_notice', locale_scope) + handler.approved_at }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="billing.status === 'fail'"
        class="p-3 mx-3 mb-3 text-danger rounded d-flex align-items-center msg-block"
      >
        <span>{{ I18n.t('error_message_notice', locale_scope) + billing.error_message }}</span>
      </div>
    </div>
    <!-- 上方是出帳檔案區 -->

    <!-- 下方是測試信區 -->
    <div class="mb-5 card" v-if='formal_mail.status === "pending"' >
      <div class="border card-header card-title">
        <span>{{ I18n.t('send_test_mail_title', locale_scope) }}</span>
        <small class="text-danger ml-2">
          {{ I18n.t('test_mail_notice_1', locale_scope) }}
        </small>
        <small class="text-danger ml-2">
          {{ I18n.t('test_mail_notice_2', locale_scope) }}
        </small>
      </div>

      <div class="p-3 border">
        <div class="table-responsive">
          <table class="datatable table table-striped table-bordered" data-server-side="true" :data-ajax="`${this.current_path}/test_mail_records`" data-order='[[ 2, "asc" ]]'>
            <thead>
              <tr>
                <th scope="col" data-searchable="false" data-sortable="false" data-name="id" data-class-name="text-center align-middle">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" id="select_all" class="custom-control-input" v-model="selectAll">
                    <label for="select_all" class="custom-control-label">{{ I18n.t('select_all') }}</label>
                  </div>
                </th>
                <th scope="col" data-sortable="true" data-name="name" data-class-name="col-5 text-center align-middle">
                  {{ I18n.t('activerecord.attributes.company.name') }}
                </th>
                <th scope="col" data-searchable="false" data-sortable="true" data-name="test_mail_record" data-class-name="col-5 text-center align-middle">
                  {{ I18n.t('activerecord.attributes.company.test_mail_record') }}
                </th>
              </tr>
            </thead>
          </table>
        </div>
        <button
          v-if='!hasSendTestMail'
          :disabled='billing.status !== "done"'
          @click="sendTestMail"
          class="my-4 mx-auto d-block btn btn-success w-25"
          type="button"
        >
          {{ I18n.t('send_test_mail', locale_scope) }}
        </button>
        <button
          v-else
          :disabled='billing.status !== "done"'
          @click="resetSendTestMail"
          class="my-4 mx-auto d-block btn btn-warning w-25"
          type="button"
        >
          {{ I18n.t('send_next_batch_test_mail', locale_scope) }}
        </button>
      </div>
    </div>
    <!-- 上方是測試信區 -->

    <!-- 下方是正式對帳信 -->
    <div class="mb-5 card">
      <div class="card-header card-title">
        <span>{{ I18n.t('formal_mail_title', locale_scope) }}</span>
        <small
          v-if="formal_mail.status !== 'done'"
          class="text-danger"
        >
          {{ I18n.t('time_evaluation_of_formal_mail', locale_scope) }}
        </small>
        <billing-task-status-tag :task_status='formal_mail.status'></billing-task-status-tag>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            v-for="(handler, handlerIndex) in formal_mail.billing_handlers"
            :key="handlerIndex"
            class="mb-3 col-4"
          >
            <div class="mx-3">
              <button
                @click="approveFormalMail(handler)"
                :disabled='handler.approved'
                :class="`btn btn-block border-success ${ handler.approved ? 'btn-success' : 'text-success' }`"
                type="button"
              >
                {{ handler.manager.full_name }}
              </button>
              <p class="text-center text-success card-text" v-if='handler.approved'>
                {{ I18n.t('confirm_time_notice', locale_scope) + handler.approved_at }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="formal_mail.status === 'fail'"
        class="p-3 mx-3 mb-3 text-danger msg-block"
      >
        {{ I18n.t('error_message_notice', locale_scope) + formal_mail.error_message }}
      </div>
    </div>
    <!-- 上方是正式對帳信 -->
  </div>
</template>

<script>
  import axios from 'axios'
  import Uppy from '@uppy/core';
  import Dashboard from '@uppy/dashboard';
  import XHRUpload from '@uppy/xhr-upload';
  import ZH_TW from '@uppy/locales/lib/zh_TW';
  import UploadFilesList from './UploadFilesList.vue';
  import BillingTaskStatusTag from './BillingTaskStatusTag.vue';

  export default {
  components: { UploadFilesList, BillingTaskStatusTag },
    props: {
      billing_event_info: {
        type: Object,
        requred: true
      },
      current_manager_id: {
        type: Number,
        requred: true
      }
    },
    data(){
      return {
        locale_scope: { scope: 'admins.billing_events.show' },
        uploadRawdataFiles: this.billing_event_info.upload_rawdata_files,
        companyAccounts: this.billing_event_info.company_accounts,
        selectedAccounts: [],
        uploadAccountsCsvFiles: [],
        billing_event: this.billing_event_info.billing_event,
        compare_rawdata: this.billing_event_info.compare_rawdata,
        import_rawdata: this.billing_event_info.import_rawdata,
        get_accounts_info: this.billing_event_info.get_accounts_info,
        adjust_account_data: this.billing_event_info.adjust_account_data,
        billing: this.billing_event_info.billing,
        formal_mail: this.billing_event_info.formal_mail,
        rawdataKey: 0,
        accountsCsvKey: 0,
        hasSendTestMail: false,
        uploadRawdataError: false,
        billingDone: this.billing_event_info.billing.status === 'done',
        datatable: null,
        selectAll: false,
      }
    },
    mounted() {
      if(this.billing_event.status === 'done') return

      // 初始化 uppy，通常會是 task pending 時需要， processing or done 的時候需要顯示上傳檔案列表
      if(this.compare_rawdata.status == 'pending') {
        // 202209 開會得知阿里 rawdata 改成 2 個檔案
        this.uploadRawdataUppy()
      }
      if(this.adjust_account_data.status == 'pending') {
        this.initUppy('#upload-accounts-excel', `${this.current_path}/upload_accounts_excel`, ['.csv', '.xlsx'], 10)
      }

      axios.defaults.headers.common['X-CSRF-TOKEN'] = this.authenticity_token;

      const tasks = [
        this.compare_rawdata,
        this.import_rawdata,
        this.get_accounts_info,
        this.adjust_account_data,
        this.billing,
        this.formal_mail,
      ]
      tasks.forEach((task) => {
        if(task.status === 'processing') {
          this.pollingCheckTaskStatus(task)
        }
      })
      this.manageDataTable()
    },
    watch: {
      selectAll() {
        let checkboxex = document.querySelectorAll('.test-mail-checkbox')
        checkboxex.forEach((checkbox) => { checkbox.checked = this.selectAll })
      }
    },
    methods: {
      manageDataTable() {
        this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            autoWidth: false,
            responsive: true,
            aLengthMenu: [10, 25, 50],
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            },
          });
      },
      initUppy(idTag, url, allowedFileTypes, fileNumber){
        let data = this
        const uppy = Uppy({
          logger: Uppy.debugLogger,
          meta: {
            authenticity_token: this.authenticity_token
          },
          restrictions: {
            allowedFileTypes: allowedFileTypes,
            maxNumberOfFiles: fileNumber
          }
        });
        uppy.use(Dashboard, {
          showProgressDetails: true,
          closeModalOnClickOutside: true,
          hideProgressAfterFinish: true,
          locale: ZH_TW,
          inline: true,
          target: idTag,
          replaceTargetContent: true,
          height: '100%',
          width: '100%',
        });
        uppy.use(XHRUpload, {
          endpoint: url,
          method: 'post',
          formData: true,
          bundle: true,
          getResponseData (responseText) {
            let result = JSON.parse(responseText)
            if(result.success) {
              Toastr('success', result.message)
            } else {
              Toastr('error', result.message)
              if(idTag === '#upload-rawdata'){
                data.uploadRawdataError = true
              }
            }
          }
        });
        uppy.on('complete', (result) => {
          if(idTag === '#upload-rawdata') {
            this.uploadRawdataFiles = result.successful.map((file) => { return file.name })
            this.rawdataKey += 1
          } else {
            this.uploadAccountsCsvFiles = result.successful.map((file) => { return file.name })
            this.accountsCsvKey += 1
          }
        })
      },
      reuploadRawdata() {
        let managerConfirm = confirm(I18n.t('are_you_sure_to_reupload_rawdata', this.locale_scope))
        if(!managerConfirm) return
        if(this.compare_rawdata.status !== 'pending') {
          Toastr('error', I18n.t('rawdata_is_comparing_can_not_reupload', this.locale_scope))
          return
        }
        this.uploadRawdataFiles = []
        this.uploadRawdataError = false

        // 當頁面上沒有 uppy 才可以再產生
        if(!document.querySelector('#upload-rawdata .uppy-Root')) {
          this.uploadRawdataUppy()
        }
        Toastr('success', I18n.t('reupload_rawdata_is_acceptable', this.locale_scope))
      },
      uploadRawdataUppy() {
        this.initUppy('#upload-rawdata', `${this.current_path}/upload_rawdata`, ['.csv'], 2)
      },
      startCompareRawdata() {
        // 跳個彈窗確認給業助再次確認檔案是否正確
        let managerConfirm = confirm(I18n.t(`${this.locale_scope.scope}.are_you_sure_to_upload_rawdata`, { num: this.uploadRawdataFiles.length }))
        if(!managerConfirm) return

        this.compare_rawdata.status = 'processing'
        axios.post(`${this.current_path}/compare_rawdata`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              // 每 10 秒確認是否比對完成
              setTimeout(() => { this.pollingCheckTaskStatus(this.compare_rawdata) }, 10000)
            } else {
              this.compare_rawdata.status = 'fail'
              this.compare_rawdata.error_message = data.message
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      resetCompareRawdataTask() {
        let managerConfirm = confirm(I18n.t('are_you_sure_to_reset_rawdata_comparison', this.locale_scope))
        if(!managerConfirm) return

        axios.post(`${this.current_path}/reset_compare_rawdata`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.compare_rawdata.status = 'pending'
              this.compare_rawdata.billing_data_folder.folder = ''
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      approveImportRawdata(handler) {
        if(handler.approved) return
        if(this.compare_rawdata.status !== 'done') {
          Toastr('error', I18n.t('not_finish_rawdata_comparison_can_not_import', this.locale_scope))
          return
        }
        if(handler.manager_id !== this.current_manager_id) {
          Toastr('error', I18n.t('can_not_click_others_botton', this.locale_scope))
          return
        }
        if(this.import_rawdata.status === 'done') return

        let managerConfirm = confirm(I18n.t('are_you_sure_to_import_rawdata', this.locale_scope))
        if(!managerConfirm) return

        axios.post(`${this.current_path}/import_rawdata`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.import_rawdata.status = 'processing'
              setTimeout(() => { this.pollingCheckTaskStatus(this.import_rawdata) }, 10000)
            } else {
              this.import_rawdata.status = 'fail'
              this.import_rawdata.error_message = data.message
              Toastr('error', data.message)
            }
            if(!!data.approved_at) {
              let current_handler = lodash.find(this.import_rawdata.billing_handlers, (handler) => {
                return handler.manager_id === this.current_manager_id
              })
              current_handler.approved = true
              current_handler.approved_at = data.approved_at
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      addAccountUid(newAccountUid) {
        const account = {
          account_uid: newAccountUid
        }
        this.selectedAccounts.push(account)
      },
      startGetAccountsInfo () {
        if(this.get_accounts_info.status !== 'pending') return

        if(this.selectedAccounts.length === 0) {
          Toastr('error', I18n.t('accounts_uid_is_necessary', this.locale_scope))
          return
        }
        const accountUids = this.selectedAccounts.map((account) => { return account.account_uid })
        this.get_accounts_info.status = 'processing'
        axios.post(`${this.current_path}/get_accounts_info`, {
          account_uids: accountUids
        }).then(({data}) => {
          if(data.success) {
            Toastr('success', data.message)
            // 每 10 秒確認是否比對完成
            setTimeout(() => { this.pollingCheckTaskStatus(this.get_accounts_info) }, 10000)
          } else {
            this.get_accounts_info.status = 'fail'
            this.get_accounts_info.error_message = data.message
            Toastr('error', data.message)
          }
        }).catch(() => {
          Toastr('error', I18n.t('system_error', this.locale_scope))
        })
      },
      redoGetAccountsInfoTask() {
        if(this.get_accounts_info.status === 'pending' || this.get_accounts_info.status === 'processing') {
          Toastr('error', I18n.t('generation_of_accounts_excel_not_finished', this.locale_scope))
          return
        }
        axios.post(`${this.current_path}/redo_get_accounts_info`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.get_accounts_info.status = 'pending'
              this.get_accounts_info.billing_data_folder.folder = ''
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      startImportAccountsExcel() {
        // 跳個彈窗確認給業助再次確認檔案是否正確
        let managerConfirm = confirm(I18n.t('are_sure_to_upload_these_accounts_excel', this.locale_scope))
        if(!managerConfirm) return

        if(this.adjust_account_data.status !== 'pending') {
          Toastr('error', I18n.t('accounts_excel_are_importing', this.locale_scope))
          return
        }

        this.adjust_account_data.status = 'processing'
        axios.post(`${this.current_path}/adjust_account_data`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              // 每 10 秒確認是否比對完成
              setTimeout(() => { this.pollingCheckTaskStatus(this.adjust_account_data) }, 10000)
            } else {
              this.adjust_account_data.status = 'fail'
              this.adjust_account_data.error_message = data.message
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      redoAdjustAccountDataTask() {
        if(this.adjust_account_data.status === 'pending' || this.adjust_account_data.status === 'processing') {
          Toastr('error', I18n.t('accounts_excel_are_importing_can_not_import_next_batch', this.locale_scope))
          return
        }

        axios.post(`${this.current_path}/redo_adjust_account_data`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.adjust_account_data.status = 'pending'
              this.adjust_account_data.billing_data_folder.folder = ''
              this.uploadAccountsCsvFiles = []
              // 當頁面上沒有 uppy 才可以再產生
              if(!document.querySelector('#upload-accounts-excel .uppy-Root')) {
                this.initUppy('#upload-accounts-excel', `${this.current_path}/upload_accounts_excel`, ['.xlsx'], 10)
              }
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      skipManualTasks() {
        if(this.import_rawdata.status != 'done') {
          return Toastr('error', 'rawdata 尚未匯入')
        }
        if(this.get_accounts_info.status === 'processing' || this.adjust_account_data.status === 'processing') {
          return Toastr('error', '人工處理帳戶進行中，無法跳過')
        }
        axios.post(`${window.location.pathname}/skip_manual_adjust`)
             .then(({ data }) => {
               if(data.success) {
                 this.get_accounts_info.status = 'done'
                 this.adjust_account_data.status = 'done'
                 Toastr('success', data.message)
               } else {
                 Toastr('error', data.message)
               }
             }).catch(() => {
               Toastr('error', I18n.t('system_error', this.locale_scope))
             })
      },
      approveBilling(handler) {
        if(handler.approved) return
        if(this.adjust_account_data.status !== 'done') {
          Toastr('error', I18n.t('import_accounts_excel_not_finished', this.locale_scope))
          return
        }
        if(handler.manager_id != this.current_manager_id) {
          Toastr('error', I18n.t('can_not_click_others_botton', this.locale_scope))
          return
        }
        if(this.billing.status === 'done') return

        let managerConfirm = confirm(I18n.t('are_you_sure_to_generate_billing_files', this.locale_scope))
        if(!managerConfirm) return

        axios.post(`${this.current_path}/billing`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.billing.status = 'processing'
              setTimeout(() => { this.pollingCheckTaskStatus(this.billing) }, 10000)
            } else {
              this.billing.status = 'fail'
              this.billing.error_message = data.message
              Toastr('error', data.message)
            }
            if(!!data.approved_at) {
              let current_handler = lodash.find(this.billing.billing_handlers, (handler) => {
                return handler.manager_id === this.current_manager_id
              })
              current_handler.approved = true
              current_handler.approved_at = data.approved_at
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      pollingCheckTaskStatus(task) {
        axios.get(`${this.current_path}/check_task_status?category=${task.category}`)
          .then(({data}) => {
            if(data.status === 'processing') {
              setTimeout(() => { this.pollingCheckTaskStatus(task) }, 10000)
            }
            if(!!data.folder) {
              this.billing_event_info[task.category].billing_data_folder.folder = data.folder
            }
            if(!!data.error_message) {
              this.billing_event_info[task.category].error_message = data.error_message
            }
            if(task.category === 'billing' && data.status === 'done') {
              this.dataTable.fnDraw(false)
            }
            if(task.category === 'formal_mail' && data.status === 'done') {
              this.billing_event_info.status = 'done'
            }
            this.billing_event_info[task.category].status = data.status
          })
          .catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      sendTestMail() {
        if(this.hasSendTestMail) return
        if(this.billing.status !== 'done') {
          Toastr('error', I18n.t('fail_to_send_test_mail', this.locale_scope))
          return
        }
        const companyIds =  Array.from(document.querySelectorAll('.test-mail-checkbox:checked')).map((checkbox) => { return checkbox.value })
        if(companyIds.length === 0) return

        this.hasSendTestMail = true
        axios.post(`${this.current_path}/send_test_mail`, { company_ids: companyIds })
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.selectAll = false
              this.dataTable.fnDraw(false)
            } else {
              Toastr('error', data.message)
            }
          }).catch((e) => {
            console.log(e)
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
      resetSendTestMail() {
        if(!this.hasSendTestMail) return
        this.hasSendTestMail = false
        Toastr('success', I18n.t('ready_to_send_next_batch_test_mail', this.locale_scope))
      },
      approveFormalMail(handler) {
        if(handler.approved) return
        if(this.billing.status !== 'done') {
          Toastr('error', I18n.t('fail_to_send_formal_mail', this.locale_scope))
          return
        }
        if(handler.manager_id != this.current_manager_id) {
          Toastr('error', I18n.t('can_not_click_others_botton', this.locale_scope))
          return
        }
        if(this.formal_mail.status === 'done') return
        let managerConfirm = confirm(I18n.t('are_sure_to_send_formal_mail', this.locale_scope))
        if(!managerConfirm) return
        axios.post(`${this.current_path}/formal_mail`)
          .then(({data}) => {
            if(data.success) {
              Toastr('success', data.message)
              this.formal_mail.status = 'processing'
              setTimeout(() => { this.pollingCheckTaskStatus(this.formal_mail) }, 10000)
            } else {
              Toastr('error', data.message)
            }
            if(!!data.approved_at) {
              let current_handler = lodash.find(this.formal_mail.billing_handlers, (handler) => {
                return handler.manager_id === this.current_manager_id
              })
              current_handler.approved = true
              current_handler.approved_at = data.approved_at
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error', this.locale_scope))
          })
      },
    },
  }
</script>

<style>
  .card-title {
    background-color: #EFEEED;
  }
  .msg-block {
    background-color: #D3D3D3;
  }
  .uppy-height {
    height: 350px;
  }
  .uppy-Dashboard-innerWrap {
    height: 350px;
  }
  .uppy-Dashboard-files .uppy-Dashboard-Item {
    width: 15%;
  }
  .upArrow:before {
    opacity: 1!important;
  }
  .downArrow:after {
    opacity: 1!important;
  }
</style>