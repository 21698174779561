<template>
  <div>
    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('info') }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="company_name">{{ I18n.t('name', locale_scope) }}</label>
          <ValidationProvider rules="required" :name="I18n.t('name', locale_scope)" v-slot="{ errors }" immediate>
          <input type="text" id="company_name" class="form-control " name='company[name]' v-model.trim="company.name" />
          <small class="text-muted d-block">請勿使用如"/"、"\"等特殊符號，以免出帳錯誤。詳情請<a href="https://cloud.google.com/storage/docs/naming-objects" target="_blank">點此Cloud Storage文件</a></small>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="form-group">
          <label for="company_description">{{ I18n.t('description', locale_scope) }}</label>
          <input type="text" id="company_description" class="form-control " name='company[description]' v-model.trim="company.description">
        </div>

        <div class="form-group">
          <label for="company_phone">{{ I18n.t('phone', locale_scope) }}</label>
          <input type="text" id="company_phone" class="form-control " name='company[phone]' v-model.trim="company.phone">
        </div>

        <div class="form-group">
          <label for="company_tax_number">{{ I18n.t('tax_number', locale_scope) }}</label>
          <input type="text" id="company_tax_number" class="form-control " name='company[tax_number]' v-model.trim="company.tax_number">
        </div>

        <div class="form-group">
          <label for="company_country">{{ I18n.t('country', locale_scope) }}</label>
          <country-select id="company_country" className="custom-select" :countryName="true" name='company[country]' v-model="country" :country="country" topCountry="Taiwan" :placeholder="I18n.t('helpers.select.prompt')" />
        </div>

        <template v-if="country == 'Taiwan'">
          <div class="row" >
            <div class="col-12 col-sm-3">
              <div class="form-group">
                  <label for="address_city">{{ I18n.t('city', locale_scope) }}</label>
                  <county v-model="city" id="address_city" name="company[city]" class="custom-select"></county>
              </div>
            </div>
            <div class="col-12 col-sm-3">
              <div class="form-group">
                <label for="address_district">{{ I18n.t('district', locale_scope) }}</label>
                <zipcode v-model="district" id="address_district" :filter-by-county="city" value-template=":city" text-template=":city" name="company[district]" class="custom-select" required></zipcode>
                <zipcode v-model="district" id="address_zipcode" value-template=":id" name="company[zipcode]" class="d-none"></zipcode>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="form-group">
                <label for="company_road">{{ I18n.t('road', locale_scope) }}</label>
                <input type="text" id="company_road" class="form-control " name='company[road]' v-model.trim="company.road">
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="form-group">
            <label for="company_road">{{ I18n.t('road', locale_scope) }}</label>
            <input id="company_road" class="form-control " name='company[road]' v-model.trim="company.road">
          </div>
          <div class="row" >
            <div class="col">
              <div class="form-group">
                <label for="company_district">{{ I18n.t('district', locale_scope) }}</label>
                <input type="text" id="company_district" class="form-control " name='company[district]' v-model.trim="company.district">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="company_city">{{ I18n.t('city', locale_scope) }}</label>
                <input type="text" id="company_city" class="form-control " name='company[city]' v-model.trim="company.city">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="company_zipcode">{{ I18n.t('zipcode', locale_scope) }}</label>
                <input type="text" id="company_zipcode" class="form-control " name='company[zipcode]' v-model.trim="company.zipcode">
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>

    <company-account-list :company_accounts="company_accounts" :company="company"></company-account-list>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('assignee', locale_scope) }}
      </div>
      <div class="card-body">
        <div class="form-group">
          <ValidationProvider rules="required" :name="I18n.t('assignee', locale_scope)" v-slot="{ errors }" immediate>
          <input type="hidden" name='company[assignee_id]' v-model="assignee.id">
          <multiselect v-model="assignee" :options="managers" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" :show-labels="false" :custom-label="nameWithEmail">
          </multiselect>
          <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <company-contact :company="company" :contact_categories="contact_categories" :blank_company_contact="blank_company_contact"></company-contact>

    <div class="mb-3 card">
      <div class="card-header">
        {{ I18n.t('inhouse') }}
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_subsidiary_id">{{ I18n.t('activerecord.models.subsidiary') }}</label>
              <ValidationProvider rules="required" :name="I18n.t('activerecord.models.subsidiary')" v-slot="{ errors }" immediate>
              <select id="company_subsidiary_id" class="custom-select" name='company[subsidiary_id]' v-model="company.subsidiary_id">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(subsidiary, index) in subsidiaries" :value="subsidiary.id" :key="index">{{subsidiary.name}}</option>
              </select>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_netsuite_id">{{ I18n.t('netsuite_id', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('netsuite_id', locale_scope)" v-slot="{ errors }" immediate>
              <input id="company_netsuite_id" class="form-control" name='company[netsuite_id]' type="text" v-model.trim="company.netsuite_id">
              <small class="text-muted d-block">{{ I18n.t('admins.companies.form.netsuite_id_changed_reminder') }}</small>
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-12 col-sm-4">
            <div class="form-group">
              <label for="company_internal_id">{{ I18n.t('internal_id', locale_scope) }}</label>
              <ValidationProvider rules="required" :name="I18n.t('internal_id', locale_scope)" v-slot="{ errors }" immediate>
              <input id="company_internal_id" class="form-control " name='company[internal_id]' type="text" v-model.trim="company.internal_id">
              <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <company-tax-code :company="company" :tax_code_options="taxCodeOptions"></company-tax-code>

          <div class="col-12 col-sm-4">
            <ValidationProvider rules="required" :name="I18n.t('assignee_id', locale_scope)" v-slot="{ errors }" immediate>
              <div class="form-group">
                <label for="company_currency">{{ I18n.t('currency', locale_scope) }}</label>
                <select v-model="company.currency" name="company[currency]" class="custom-select">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <option v-for="(currency, currency_index) in currencies" :value="currency" :key="currency_index">
                    {{ I18n.t(`simple_form.labels.company.currency.${currency}`)}}
                  </option>
                </select>
                <small class="text-muted d-block">如有預付費餘額，請先人工換成等值貨幣金額，再變更幣別</small>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="form-group">
          <input name="company[manual_billing]" type="hidden" value="0">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" id="company_manual_billing" name="company[manual_billing]" value="1" class="custom-control-input" v-model="company.manual_billing">
            <label for="company_manual_billing" class="custom-control-label">{{ I18n.t('manual_billing', locale_scope) }}</label>
            <small class="text-primary">出帳時，公司對帳信會寄到業助群組，再由業助人工處理</small>
          </div>
        </div>
      </div>
    </div>
    <comment-form :commentable="'company'" :comments="comments" :comment_categories="comment_categories"></comment-form>
  </div>
</template>

<script>
  import { CountrySelect } from 'vue-country-region-select'
  import { Zipcode, County } from 'twzipcode-vue'
  export default {
    components: {
      CountrySelect,
      // 縣市
      County,

      // 郵遞區號
      Zipcode,
    },
    props: {
      company_form: {
        type: Object,
        requred: true
      },
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.company'
        },
        company: this.company_form.company,
        managers: this.company_form.managers,
        subsidiaries: this.company_form.subsidiaries,
        taxCodeOptions: this.company_form.tax_code_options,
        company_accounts: this.company_form.company_accounts,
        blank_company_contact: this.company_form.blank_company_contact,
        contact_categories: this.company_form.contact_categories,
        comments: this.company_form.company.comments,
        comment_categories: this.company_form.comment_categories,
        country: this.company_form.company.country || 'Taiwan',
        city: this.company_form.company.city || "",
        district: this.company_form.company.district || "",
        zipcode: this.company_form.company.zipcode || "",
        road: this.company_form.company.road || "",
        currencies: this.company_form.currencies,
        assignee: this.company_form.managers.find(manager => manager.id === this.company_form.company.assignee_id) || {}
      }
    },
    methods: {
      nameWithEmail({name, email}){
        if (lodash.isEmpty(this.managers) || lodash.isUndefined(name) || lodash.isUndefined(email)){
          return ''
        } else {
          return `${name} (${email})`
        }
      },
    },
  }
</script>

<style>

</style>
