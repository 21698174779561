<template>
  <div>
    <div class="mb-2 d-flex justify-content-end">
      <a :href="`${url}/download`" class="btn btn-primary" data-method="post">{{ I18n.t('download_all') }}</a>
      <form :action="`${url}/download`" method="post" class="simple_form" accept-charset="UTF-8" v-if="selected_ids.length > 0">
        <input type="hidden" name="authenticity_token" v-model="authenticity_token">
        <input type="hidden" name="object_ids[]" v-for="(selected_id, index) in selected_ids" :value="selected_id" :key="index">
        <button type="submit" class="ml-2 btn btn-primary"> {{ I18n.t('download') }} </button>
      </form>
    </div>
    <div class="table-responsive">
      <table class="table datatable table-striped table-hover" data-order='[[ "1", "asc" ]]'>
        <thead>
          <tr>
            <th scope="col" data-name="id" data-sortable="false" data-class-name="text-center align-middle">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" id="select_all" class="custom-control-input">
                <label for="select_all" class="custom-control-label">{{ I18n.t('select_all') }}</label>
              </div>
            </th>
            <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle">
                {{ I18n.t('name', locale_scope) }}
            </th>
            <th scope="col" data-name="size" data-sortable="true" data-class-name="text-center align-middle">
                {{ I18n.t('size', locale_scope) }}
            </th>
            <th scope="col" data-name="created_at" data-sortable="true" data-class-name="text-center align-middle">
                {{ I18n.t('created_at') }}
            </th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      authenticity_token: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        locale_scope: {
          scope: 'activerecord.attributes.billing_file_folder'
        },
        url: window.location.pathname,
        dataTable: null,
        select_all: false,
        selected_ids: [],
      }
    },
    mounted() {
      const vm = this
        this.dataTable = $(this.$el.querySelector(".datatable"))
          .DataTable({
            ajax: {
              url: `${window.location.pathname}.json`
            },
            language: {
              url: `/datatable.${I18n.currentLocale()}.lang.json`,
            },
            drawCallback: function(){
              const $table = $(this)
              vm.bindingCheckbox($table);
            }
          });
          // 5分鐘重整更新media_link

        setInterval(() => {
          this.reloadTable()
        }, 5*60*1000);
    },
    methods: {
      reloadTable(){
        this.dataTable.ajax.reload()
        this.selected_ids = []
      },
      bindingCheckbox($table){
        // 全選
        $table.off("change", "#select_all")
        $table.on("change", "#select_all", function(){
          if (this.checked) {
            $("tbody :checkbox:not(:checked)").trigger("click");
          } else {
            $("tbody :checkbox:checked").trigger("click");
          }
        })

        // 個別選
        $table.off("change", "tbody :checkbox")
        $table.on("change", "tbody :checkbox", ({target}) => {
          this.setId(target);
        });
      },
      setId(selected_input){
        const $select_all = $('#select_all')
        const changed_id = $(selected_input).val()
        if(selected_input.checked){
          this.selected_ids.push(changed_id)
        } else {
          const index = this.selected_ids.indexOf(changed_id);
          if (index > -1) {
            this.selected_ids.splice(index, 1);
          }
        }
        $select_all.prop("indeterminate", false);
        if (this.selected_ids.length === 0) {
          $select_all.prop("checked", false);
        } else if (this.selected_ids.length === $("tbody :checkbox").length) {
          $select_all.prop("checked", true);
        } else {
          $select_all.prop("checked", false);
          $select_all.prop("indeterminate", true);
        }

      }
    },
  };
</script>
