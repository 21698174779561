import Rails from "@rails/ujs"
Rails.start()
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

window.jQuery = $
window.$ = $
window.Rails = Rails
import "../stylesheets/libs";
import "../javascripts/libs";
