<template>
  <div class="mb-3 card">
    <div class="card-header">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="turn_on_prepaid_fee_form" v-model="showForm">
        <label class="custom-control-label"  for="turn_on_prepaid_fee_form">{{ I18n.t('new') }}{{ I18n.t('activerecord.models.prepaid_fee_history') }}</label>
      </div>
    </div>
    <div class="card-body" v-if='showForm'>
      <div class="row">
        <div class="col-12">

          <ValidationProvider rules="required" :name="I18n.t('category', locale_scope)" v-slot="{ errors }" immediate>
            <div class="form-group">
              <label>{{ I18n.t('category', locale_scope) }}</label>
              <select class="custom-select" v-model="category">
                <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                <option v-for="(action, action_index) in categories" :value="action" :key="action_index">
                  {{ I18n.t(`simple_form.options.prepaid_fee_history.category.${action}`)}}
                </option>
              </select>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>

          <div class="form-group">
            <label>{{ I18n.t('value', locale_scope) }}</label>
            <input type="text" id="prepaid_fee_history_value" class="form-control" inputmode="decimal" required v-model="value">
            <span class="text-danger" v-if="valueError" >{{ valueErrorMessage }}</span>
          </div>

          <!-- 備註改為非必填 -->
          <!-- <ValidationProvider :rules="{ required: true, regex: /^[^\\\/]+$/ }" :name="I18n.t('note', locale_scope)" v-slot="{ errors }" immediate> -->
          <div class="form-group">
            <label for="prepaid_fee_history_note">{{ I18n.t('note', locale_scope) }}</label>
            <small class="text-muted">請勿使用如"/"、"\"等特殊符號</small>
            <input type="text" id="prepaid_fee_history_note" class="form-control" v-model="note">
            <!-- <span class="text-danger">{{ errors[0] }}</span> -->
          </div>
          <!-- </ValidationProvider> -->

          <div class="form-group">
            <button id="prepaid_fee_history_form_btn" type="button" class="btn btn-primary btn-lg btn-block" disabled @click="createPrepaidFeeHistory" >
              {{ I18n.t('create') }}{{ I18n.t('activerecord.models.prepaid_fee_history') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  export default {
    props: {
      categories: {
        type: Array,
        requred: true,
      },
      url: {
        type: String,
        requred: true
      },
      only_integer: {
        type: Boolean,
        requred: true
      }
    },
    data(){
      return {
        locale_scope: { scope: 'activerecord.attributes.prepaid_fee_history' },
        locale_error_scope: { scope: 'simple_form.errors.prepaid_fee_history' },
        showForm: false,
        category: 'consume',
        value: '',
        note: '',
        valueError: true,
        valueErrorMessage: I18n.t('simple_form.errors.prepaid_fee_history.value.empty'),
        invoked: false,
      }
    },
    methods: {
      createPrepaidFeeHistory() {
        if(this.invoked) return

        this.invoked = true

        const authenticity_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

        axios.defaults.headers.common['X-CSRF-TOKEN'] = authenticity_token;
        axios.post(this.url, {
          prepaid_fee_history: { category: this.category, value: this.value, note: this.note }
        }).then( ({data}) => {
          if(data.success){ setTimeout(() => { location.href = data.link }, 3000) }
          Toastr(data.type, data.msg)
        }).catch(() => {
          Toastr('error', I18n.t('system_error_sync_rd'))
        })
        this.invoked = false
      }
    },
    computed: {
      listenDisableSubmit() {
        return {
          valueError: this.valueError,
          invoked: this.invoked,
        }
      },
      listenCategoryAndValue() {
        return {
          category: this.category,
          value: this.value
        }
      }
    },
    watch: {
      listenCategoryAndValue(current) {
        const { value, category } = current
        this.valueError = true

        if(value === '') return this.valueErrorMessage = I18n.t('value.empty', this.locale_error_scope)

        // 確認輸入的是合格數字
        if(!value.match(/^(0|[1-9]\d*)\.?\d*$/)) return this.valueErrorMessage = I18n.t('value.invalid_format', this.locale_error_scope)

        // 非台幣支援到小數點後兩位
        if(!this.only_integer && !value.match(/^(0|[1-9]\d*)\.?\d{0,2}$/)) return this.valueErrorMessage = I18n.t('value.support_to_two_decimal_places', this.locale_error_scope)

        // 台幣只能整數
        if(this.only_integer && !value.match(/^(0|[1-9]\d*)$/)) return this.valueErrorMessage = I18n.t('value.twd_with_float', this.locale_error_scope)

        // revise 可以大於等於 0，其他只能大於 0
        if(category !== 'revise' && value <= 0) {
          return this.valueErrorMessage = I18n.t('value.less_than_zero', this.locale_error_scope)
        } else if(category === 'revise' && value < 0) {
          return this.valueErrorMessage = I18n.t('value.less_than_or_equal_to_zero', this.locale_error_scope)
        }

        this.valueError = false
        this.valueErrorMessage = null
      },
      listenDisableSubmit(current) {
        if(!this.showForm) return  // 如果表單有顯示才進去判斷
        const btn = document.querySelector('#prepaid_fee_history_form_btn')
        btn.disabled = true
        if(current.valueError) return
        if(current.invoked) return
        btn.disabled = false
      },
    },
  }
</script>
