<template>
  <div>
    <div class="mb-3 card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="year_month">{{ I18n.t('year_month', locale_scope) }}</label>
              <month-picker :name="'year_month'" :current_date="''" @changeDate="changeDate"></month-picker>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="company">{{ I18n.t('company', locale_scope) }}</label>
              <multiselect v-model="company" :options="companies" :placeholder="I18n.t('helpers.select.prompt')" label="name" track-by="id" @input="changeCompany">
              </multiselect>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="contract">{{ I18n.t('contract', locale_scope) }}</label>
              <multiselect v-model="contract" :options="contracts" :placeholder="I18n.t('helpers.select.prompt')" label="uid" track-by="id">
              </multiselect>
            </div>
          </div>
        </div>
        <div class="form-group d-flex justify-content-between">
          <button class="btn btn-success" :disabled="disable_btn" @click.prevent="acquireInvoice">{{ I18n.t('submit', locale_scope) }}</button>
          <div v-if="pdf_signed_url">
            <a :href="downloadLink('xlsx')" target="_blank" class="mx-2 btn btn-primary">Excel</a>
            <a :href="downloadLink('pdf')" target="_blank" class="btn btn-primary">PDF</a>
          </div>
        </div>
      </div>
    </div>
    <iframe :src="pdf_signed_url" v-if="pdf_signed_url" id="invoice-pdf" height="100%" width="100%" scrolling="auto"></iframe>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    data(){
      return {
        locale_scope: { scope: 'admins.invoices.index' },
        company: null,
        companies: [],
        contract: null,
        contracts: [],
        year_month: null,
        disable_btn: true,
        pdf_signed_url: null,
      }
    },
    mounted() {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    methods: {
      changeDate(date) {
        this.year_month = date
        if(!this.year_month) return

        axios.get(`/invoices/company_list?year_month=${this.year_month}`)
          .then(({data}) => {
            if(data.success) {
              this.companies = data.companies
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error_sync_rd'))
          })
      },
      changeCompany() {
        if(!this.company) return

        axios.get(`/invoices/contract_list?company_id=${this.company.id}`)
          .then(({data}) => {
            if(data.success) {
              this.contracts = data.contracts
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error_sync_rd'))
          })

      },
      acquireInvoice() {
        const params = `year_month=${this.year_month}&company_id=${this.company.id}&contract_id=${this.contract.id}`
        axios.get(`/invoices/acquire_invoice?${params}`)
          .then(({data}) => {
            if(data.success) {
              this.pdf_signed_url = data.pdf
            } else {
              Toastr('error', data.message)
            }
          }).catch(() => {
            Toastr('error', I18n.t('system_error_sync_rd'))
          })
      },
      downloadLink(extname) {
        if(!this.year_month) return
        if(!this.company) return
        if(!this.contract) return
        return `/invoices/download_file?year_month=${this.year_month}&company_id=${this.company.id}&contract_id=${this.contract.id}&extname=${extname}`
      }
    },
    computed: {
      listenInvoice() {
        return {
          year_month: this.year_month,
          company: this.company,
          contract: this.contract,
        }
      },
    },
    watch: {
      year_month: function() {
        this.company = null
        this.companies = []
        this.contract = null
        this.contracts = []
        this.pdf_signed_url = null
      },
      company: function() {
        this.contract = null
        this.contracts = []
        this.pdf_signed_url = null
      },
      contract: function() {
        this.pdf_signed_url = null
      },
      listenInvoice(current) {
        this.disable_btn = true
        if(current.year_month && current.company && current.contract) {
          this.disable_btn = false
        }
      }
    }
  }
</script>

<style>
  #invoice-pdf {
    height: 960px;
  }
</style>
