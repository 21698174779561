<template>
  <div :key="key">
    <div class="mb-3 card">
      <div class="card-body">
        <admin-billing-file-index :download_url="download_url" :authenticity_token="authenticity_token"></admin-billing-file-index>
      </div>
    </div>
    <div class="mb-3 card" v-if="editable">
      <div class="card-header">
        <span>{{ I18n.t('edit') }}</span>

        <small class="text-danger">{{ I18n.t('admins.billing_file_folders.show.notice_for_upload_files') }}</small>
      </div>
      <div class="uppy-area-wrapper">
        <div class="h-100 uppy-area"></div>
      </div>
    </div>
  </div>
</template>
<script>
  import Uppy from "@uppy/core";
  import Dashboard from "@uppy/dashboard";

  import XHRUpload from "@uppy/xhr-upload";
  import ZH_TW from "@uppy/locales/lib/zh_TW";

  export default {
    props: {
      upload_url: {
        type: String
      },
      download_url: {
        type: String
      },
      editable: {
        type: Boolean,
        default: () => {
          return false
        }
      },
    },
    data(){
      return {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        key: 0
      }
    },
    mounted() {
      if(this.editable){
        this.initUppy()
      }
    },
    methods: {
      initUppy(){
        const uppy = Uppy({
          logger: Uppy.debugLogger,
          meta: {
            authenticity_token: this.authenticity_token
          },
          restrictions: {
            maxNumberOfFiles: 10,
          }
        });
        uppy.use(Dashboard, {
          showProgressDetails: true,
          closeModalOnClickOutside: true,
          hideProgressAfterFinish: true,
          locale: ZH_TW,
          inline: true,
          target: ".uppy-area",
          replaceTargetContent: true,
          height: "100%",
          width: "100%",
        });
        uppy.use(XHRUpload, {
          endpoint: this.upload_url,
          method: 'post',
          formData: true,
          bundle: true,
          getResponseData (responseText) {
            const result = JSON.parse(responseText)
            Toastr(result.success, result.message)
            if(result.success === 'success') { setTimeout(()=> { location.reload()  }, 3000) }
          }
        });
      }
    },
  };
</script>
