<template>
  <div>
    <div class="mb-3 card">
      <div class="card-body">
        <div>
          <ValidationProvider :rules="{ required: true }" :name="I18n.t('category', locale_scope)" v-slot="{ errors }" immediate>
            <div class="form-group">
              <label for="commit_group_category">{{ I18n.t('category', locale_scope) }}</label>
              <input type="hidden" name="commit_group[category]" v-model="commit_group.category">
              <multiselect v-model="commit_group.category" :options="commit_group_categories" :placeholder="I18n.t('helpers.select.prompt')" :custom-label="categoryWithLocale" :allow-empty="false">
              </multiselect>
              <span class="text-danger">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div>
          <div class="form-group">
            <label for="commit_group_service_name">{{ I18n.t('activerecord.models.product') }}</label>
            <input
              type="hidden"
              name='commit_group[product_id]'
              v-model="commit_group.product_id"
            >
            <multiselect
              v-model="commit_group.product"
              :options="products_list"
              :placeholder="I18n.t('helpers.select.prompt')"
              label="name"
              track-by="id"
              :allow-empty="false"
              @select="setProduct"
            >
            </multiselect>
            <div class="text-muted">若選單中查無選項，請<a href="/products" target="_blank">點此新增</a>，再重新整理此頁面。</div>
          </div>
        </div>
        <div>
          <div class="form-group">
            <label for="commit_group_region">{{ I18n.t('activerecord.models.region') }}</label>
            <input
              type="hidden"
              name='commit_group[region_id]'
              v-model="commit_group.region_id"
            >
            <multiselect
              v-model="commit_group.region"
              :options="regions_list"
              :placeholder="I18n.t('helpers.select.prompt')"
              label="name"
              track-by="id"
              :allow-empty="false"
              @select="setRegion"
            >
            </multiselect>
            <div class="text-muted">若選單中查無選項，請<a href="/regions" target="_blank">點此新增</a>，再重新整理此頁面。</div>
          </div>
        </div>
      </div>

      <div class="px-3">
        <contract-commit-fee-form :contract_commit_fees="contract_commit_fees"></contract-commit-fee-form>
      </div>
    </div>

    <comment-form :commentable="'commit_group'" :comments="comments" :comment_categories="comment_categories"></comment-form>
  </div>
</template>

<script>
  export default {
    props: {
      commit_group_form: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        locale_scope: { scope: 'activerecord.attributes.commit_group' },
        locale_error_scope: { scope: 'simple_form.errors.commit_group' },
        commit_group: this.commit_group_form.commit_group,
        commit_group_categories: this.commit_group_form.commit_group_categories,
        products_list: this.commit_group_form.products,
        regions_list: this.commit_group_form.regions,
        contract_commit_fees: this.commit_group_form.contract_commit_fees,
        comments: this.commit_group_form.comments,
        comment_categories: this.commit_group_form.comment_categories,
      }
    },
    mounted() {
      const product_id = this.commit_group.product_id
      if(product_id) {
        this.commit_group.product = this.products_list.find(({id}) => id === product_id )
      }

      const region_id = this.commit_group.region_id
      if(region_id) {
        this.commit_group.region = this.regions_list.find(({id}) => id === region_id )
      }
    },
    methods: {
      categoryWithLocale(category){
        return I18n.t(`simple_form.options.commit_group.category.${category}`)
      },
      setProduct(product){
        this.commit_group.product_id = product.id
      },
      setRegion(region){
        this.commit_group.region_id = region.id
      }
    }
  }
</script>
