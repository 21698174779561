<template>
  <div class="mb-3 card">
    <div class="card-header">
      {{ I18n.t('activerecord.models.exchange_rate') }}
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="contract_rate_category">{{ I18n.t('rate_category', locale_scope) }}</label>
            <select id="contract_rate_category" v-model="contract.rate_category" name="contract[rate_category]" class="custom-select">
              <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
              <option v-for="(rate_category, rate_category_index) in rate_categories" :value="rate_category" :key="rate_category_index">
                {{ I18n.t(`simple_form.labels.contract.rate_category.${rate_category}`)}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <template v-if="contract.rate_category == 'fixed'">
            <ValidationProvider :rules="{ required: true, min_value: 0 }" :name="I18n.t('rate_fixed_value', locale_scope)" v-slot="{ errors }" immediate>
              <div class="form-group">
                <label for="contract_rate_fixed_value">{{ I18n.t('rate_fixed_value', locale_scope) }}</label>
                <input type="text" id="contract_rate_fixed_value" class="form-control" name='contract[rate_fixed_value]' v-model="contract.rate_fixed_value">
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </template>
          <template v-else>
            <ValidationProvider :rules="{ required: true, oneOf: rate_dates }" :name="I18n.t('rate_date', locale_scope)" v-slot="{ errors }" immediate>
              <div class="form-group">
                <label for="contract_rate_date">{{ I18n.t('rate_date', locale_scope) }}</label>
                <select id="contract_rate_date" v-model="contract.rate_date" name="contract[rate_date]" class="custom-select">
                  <option disabled value> {{ I18n.t('helpers.select.prompt')}}</option>
                  <option v-for="(rate_date, rate_date_index) in rate_dates" :value="rate_date" :key="rate_date_index">
                    {{ rate_date }}
                  </option>
                </select>
                <small class="text-muted">-1為前一月最後工作日</small>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      contract: {
        type: Object
      },
      rate_categories: {
        type: Array
      },
      rate_dates: {
        type: Array
      }
    },
    data(){
      return {
        I18n,
        locale_scope: {
          scope: 'activerecord.attributes.contract'
        },
      }
    }
  }
</script>
