<template>
  <div class="mb-3 card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <span>{{ I18n.t('activerecord.models.contract_commit_fee') }}</span>
      <button type="button" class="btn btn-primary" @click.prevent="newFee">
        <i class="fas fa-plus"></i>
      </button>
    </div>
    <div class="card-body" v-if="selected_fees.length > 0">
      <contract-commit-fee-item :contract_commit_fee_item="contract_commit_fee" v-for="(contract_commit_fee, contract_commit_fee_index) in selected_fees" :index="contract_commit_fee_index" :selected_fees_count="selected_fees.length" :key="`contract_commit_fee_${contract_commit_fee_index}`" @deleteFee="deleteFee" @assignPreviousUpperLimit="assignPreviousUpperLimit" :name_prefix="name_prefix"></contract-commit-fee-item>
    </div>
  </div>
</template>

<script>
  export default {
    props:{
      contract_commit_fees: {
        type: Array,
        required: true
      },
      name_prefix: {
        type: String
      }
    },
    data(){
      return {
        selected_fees: this.contract_commit_fees,
      }
    },
    computed: {
      valid_selected_fees(){
        return this.selected_fees.filter(({_destroy}) => _destroy != '-1' )
      },
    },
    methods: {
      newFee() {
        const new_fee = [{
          id: null,
          lower_limit: null,
          upper_limit: null,
          discount_rate: null,
          _destroy: null
        }]
        this.selected_fees = [
          ...this.selected_fees, ...new_fee
        ]
      },
      previous_valid_fee(index){
        return this.selected_fees.slice(0, index).filter(({_destroy}) => _destroy != '-1' ).slice(-1).pop()
      },
      next_valid_fee(index){
        return this.selected_fees.slice(index).filter(({_destroy}) => _destroy != '-1' )[0]
      },
      deleteFee(index) {
        // 前後接合
        if(this.next_valid_fee(index)){
          this.assignPreviousUpperLimit(this.next_valid_fee(index).lower_limit, index)
        } else {
          this.previous_valid_fee(index).upper_limit = null
        }
      },
      assignPreviousUpperLimit(value, index){
        this.previous_valid_fee(index).upper_limit = value
      }
    }
  }
</script>
