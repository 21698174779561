<template>
  <div class="mb-3 card">
    <div class="card-header">
      <input name="contract[support_enabled]" type="hidden" v-model="contract.support_enabled">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="contract_support_enabled" name="contract[support_enabled]" v-model="contract.support_enabled">
        <label class="custom-control-label"  for="contract_support_enabled">{{ I18n.t('activerecord.models.contract_support') }}</label>
      </div>
    </div>
    <div class="card-body" v-if="contract.support_enabled">
      <div class="row">
        <div class="col-12 col-sm-6">
          <input type="hidden" name='contract[contract_support_attributes][id]' v-model="contract_support.id">
          <div class="form-group">
            <label for="contract_support_charge_percent">{{ I18n.t('charge_percent', locale_scope) }}</label>
            <ValidationProvider :rules="{ required: true, min_value: 0 }" :name="I18n.t('charge_percent', locale_scope)" v-slot="{ errors }" immediate>
              <div class="input-group">
                  <input type="text" id="contract_support_charge_percent" class="form-control" name='contract[contract_support_attributes][charge_percent]' v-model="contract_support.charge_percent">
                  <div class="input-group-append">
                    <span class="input-group-text">％</span>
                  </div>
              </div>
              <span class="text-danger">{{ errors[0] }}</span>
              <small class="text-muted">(100 + {{ contract_support.charge_percent }}) = {{ 100 + Number(contract_support.charge_percent) }}%</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <ValidationProvider :rules="{ required: true, min_value: 0 }" :name="I18n.t('charge_minimum', locale_scope)" v-slot="{ errors }" immediate>
            <div class="form-group">
              <label for="contract_support_charge_minimum">{{ I18n.t('charge_minimum', locale_scope) }}</label>
              <input type="text" id="contract_support_charge_minimum" class="form-control" name='contract[contract_support_attributes][charge_minimum]' v-model="contract_support.charge_minimum">
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <span class="text-muted">*{{ I18n.t('activerecord.models.contract_support') }}費用為：「使用費用x{{ I18n.t('charge_percent', locale_scope) }}」或「{{ I18n.t('charge_minimum', locale_scope) }}」最高值者</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      contract: {
        type: Object
      },
      blank_contract_support: {
        type: Object
      }
    },
    data(){
      return {
        locale_scope: {
          scope: 'activerecord.attributes.contract_support'
        },
        contract_support: this.contract.contract_support || this.blank_contract_support,
      }
    }
  }
</script>
