<template>
  <div class="table-responsive">
    <table class="table datatable table-striped table-hover w-100" data-order='[[ "1", "asc" ]]'>
      <thead>
        <tr>
          <th scope="col" data-name="product_category_id" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('activerecord.models.product_category') }}
          </th>
          <th scope="col" data-name="name" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('name', locale_scope) }}
          </th>
          <th scope="col" data-name="code" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('code', locale_scope) }}
          </th>
          <th scope="col" data-name="commit_group_products_count" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('commit_group_products_count', locale_scope) }}
          </th>
          <th scope="col" data-name="is_rebate" data-sortable="true" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('is_rebate', locale_scope) }}
          </th>
          <th scope="col" data-name="" data-sortable="false" data-class-name="text-center align-middle" class='text-center'>
              {{ I18n.t('action') }}
          </th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: `${window.location.pathname}.json`
    }
  },
  data() {
    return {
      locale_scope: {
        scope: 'activerecord.attributes.product'
      },
      dataTable: null,
    }
  },
  mounted() {
      this.dataTable = $(this.$el.querySelector(".datatable"))
        .DataTable({
          responsive: true,
          ajax: {
            url: this.url
          },
          language: {
            url: `/datatable.${I18n.currentLocale()}.lang.json`,
          },
        }).api().columns.adjust();
  },
  methods: {
    reloadTable(){
      this.dataTable.ajax.reload()
    },
  },
};
</script>
