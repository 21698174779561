<template>
  <div class="mb-3 card">
    <div class="card-header">
      <input name="contract[contract_commit_fee_enable]" type="hidden" value="0">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="contract_contract_commit_fee_enable" name="contract[contract_commit_fee_enable]" v-model="contract.contract_commit_fee_enable">
        <label class="custom-control-label"  for="contract_contract_commit_fee_enable">{{ I18n.t('contract_commit_fee_enable', locale_scope) }}</label>
      </div>
    </div>
    <div class="card-body" v-if='contract.contract_commit_fee_enable'>
      <div class="d-none">
        <input type="hidden" :name="`${name_prefix}[id]`" v-model="commit_group_by_contract.id">
        <input type="hidden" :name="`${name_prefix}[contract_id]`" v-model="commit_group_by_contract.contract_id">
        <input type="hidden" :name="`${name_prefix}[category]`" v-model="commit_group_by_contract.category">
        <input type="hidden" :name="`${name_prefix}[is_by_service]`" v-model="commit_group_by_contract.is_by_service">
        <input type="hidden" :name="`${name_prefix}[product_id]`" v-model="commit_group_by_contract.product_id">
        <input type="hidden" :name="`${name_prefix}[region_id]`" v-model="commit_group_by_contract.region_id">
        <input type="hidden" :name="`${name_prefix}[status]`" v-model="commit_group_by_contract.status">
      </div>
      <contract-commit-fee-form :name_prefix="name_prefix" :contract_commit_fees="contract_commit_fees"></contract-commit-fee-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object
    },
    commit_group_by_contract: {
      type: Object
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract'
      },
      name_prefix: 'contract[commit_group_by_contract_attributes]',
      contract_commit_fees: this.commit_group_by_contract.contract_commit_fees
    }
  }
}
</script>
