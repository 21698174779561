<template>
  <div class="form-group">
    <div class="row" :key="key" v-show="!isDeleted()">
      <div class="col">
        <input type="hidden" v-model="contract_commit_fee.id" :name="`${name_prefix}[contract_commit_fees_attributes][${index}][id]`">
        <input type="hidden" v-model="contract_commit_fee._destroy" :name="`${name_prefix}[contract_commit_fees_attributes][${index}][_destroy]`">
        <label :for="`commit_group_lower_limit_${index}`">{{ I18n.t('lower_limit', locale_scope) }}</label>
        <ValidationProvider tag="div" class="w-100" :rules="{ required: true, regex: /^(0|[1-9]\d*)\.?\d*$/ }" :name="I18n.t('lower_limit', locale_scope)" v-slot="{ errors }" immediate>
          <input
            type="text"
            class="form-control"
            v-model.trim="contract_commit_fee.lower_limit"
            :name="`${name_prefix}[contract_commit_fees_attributes][${index}][lower_limit]`"
            :id="`commit_group_lower_limit_${index}`"
            :readonly="index === 0"
          >
          <small class="text-muted mr-3">{{ index === 0 ? '第一筆下限必須為 0' : '' }}</small>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col">
        <label :for="`commit_group_upper_limit_${index}`">{{ I18n.t('upper_limit', locale_scope) }}</label>
        <span>(小於)</span>
        <input type="hidden" v-model.trim="contract_commit_fee.upper_limit" :name="`${name_prefix}[contract_commit_fees_attributes][${index}][upper_limit]`">
        <input type="text" class="form-control" v-model.trim="contract_commit_fee.upper_limit" readonly :id="`commit_group_upper_limit_${index}`">
        <small class="text-muted">{{ is_upper_edge ? '空值為無上限' : '同下一筆下限' }}</small>
      </div>
      <div class="col">
        <label :for="`commit_group_discount_rate_${index}`">{{ I18n.t('discount_rate', locale_scope) }}</label>
        <ValidationProvider tag="div" class="w-100" :rules="{ required: true, regex: /^(0|[1-9]\d*)\.?\d*$/ }" :name="I18n.t('discount_rate', locale_scope)" v-slot="{ errors }" immediate>
          <input type="text" class="form-control" v-model.trim="contract_commit_fee.discount_rate" :name="`${name_prefix}[contract_commit_fees_attributes][${index}][discount_rate]`" :id="`commit_group_discount_rate_${index}`">
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-auto">
        <label class="w-100">&nbsp; </label>
        <button type="button" class="ml-2 btn btn-danger" @click.prevent="deleteFee(index)" v-if="can_delete">
          <i class="fas fa-trash"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contract_commit_fee_item: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    selected_fees_count: {
      type: Number
    },
    name_prefix: {
      type: String,
      default: 'commit_group'
    }
  },
  data(){
    return {
      locale_scope: {
        scope: 'activerecord.attributes.contract_commit_fee'
      },
      contract_commit_fee: this.contract_commit_fee_item,
      is_lower_edge: this.index == 0,
      key: 0
    }
  },
  computed: {
    can_delete(){
      return !this.is_lower_edge
    },
    is_upper_edge(){
      return this.index + 1 == this.selected_fees_count
    }
  },
  methods: {
    deleteFee(index) {
      this.contract_commit_fee_item._destroy = '-1'
      this.$emit('deleteFee', index)
      this.key += 1
    },
    isDeleted(){
      return this.contract_commit_fee_item._destroy == '-1'
    }
  },
  watch: {
    "contract_commit_fee.lower_limit": function(lower_limit){
      if (this.index === 0) {
        this.contract_commit_fee_item.lower_limit = 0.0
      }

      if (this.index > 0){
        this.$emit('assignPreviousUpperLimit', lower_limit, this.index)
      }
    }
  }
}
</script>
