<template>
  <div class="mb-3 card">
    <div class="card-body">
      <ValidationProvider :rules="{ required: true, regex: /^[^\\\/]+$/ }" :name="I18n.t('code', locale_scope)" v-slot="{ errors }" immediate>
        <div class="form-group">
          <label for="product_code">{{ I18n.t('code', locale_scope) }}</label>
          <input type="text" id="product_code" class="form-control" name='product[code]' v-model="product.code">
          <small class="form-text text-muted">{{ I18n.t('simple_form.hints.product.code') }}</small>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <ValidationProvider :rules="{ required: true, regex: /^[^\\\/]+$/ }" :name="I18n.t('name', locale_scope)" v-slot="{ errors }" immediate>
        <div class="form-group">
          <label for="product_name">{{ I18n.t('name', locale_scope) }}</label>
          <input type="text" id="product_name" class="form-control" name='product[name]' v-model="product.name">
          <small class="text-muted">{{ I18n.t('simple_form.hints.product.name') }}</small>
          <span class="text-danger">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>

      <div class="form-group">
        <label for="product_category">{{ I18n.t('activerecord.models.product_category') }}</label>
        <template v-if="product.product_category && product.product_category.name">
          <input type="hidden" name="product[product_category_id]" v-model="product.product_category.id">
          <input type="hidden" name="product[product_category_attributes][id]" v-model="product.product_category.id">
          <input type="hidden" name="product[product_category_attributes][name]" v-model="product.product_category.name">
        </template>
        <multiselect
          v-model="product.product_category"
          :options="product_categories"
          :placeholder="I18n.t('helpers.select.prompt')"
          track-by="id"
          label="name"
          :taggable="true"
          @tag="addCategory"
          :tag-placeholder="I18n.t('create_option')"
        >
        </multiselect>
      </div>

      <div class="form-group">
        <div class="custom-control custom-checkbox">
          <input name="product[is_rebate]" type="hidden" value="0">
          <input type="checkbox" id="product_is_rebate" name="product[is_rebate]" class="custom-control-input" v-model="product.is_rebate">
          <label for="product_is_rebate" class="custom-control-label">{{ I18n.t('is_rebate', locale_scope) }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product_form: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        locale_scope: { scope: 'activerecord.attributes.product' },
        product: this.product_form.product,
        product_categories: this.product_form.product_categories,
        is_new_record: this.product_form.product.id == undefined
      }
    },
    methods: {
      addCategory(new_category) {
        const new_product_option = {
          id: null,
          name: new_category,
        }

        // 新增選項至已選
        this.product.product_category = new_product_option

        // 新增選項至清單
        this.product_categories.push(new_product_option)
      },
    },
  }
</script>
