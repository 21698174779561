import "libs/i18n";
import "libs/day";
import "libs/localtime";
import "libs/fontawesome";
import "libs/bootstrap";
import "libs/flatpickr";
import "libs/toastr";
import "libs/sweetalert";
import "libs/tippy";

import "libs/datatable";

import Vue from "vue";
// import store from '../store';

// fontawesome
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(fas);
library.add(far);
library.add(fab);
dom.watch();
Vue.component("font-awesome-icon", FontAwesomeIcon);

// vue multiselect
import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);

// vue bootstrap
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

// textarea autosize
import VueTextareaAutosize from "vue-textarea-autosize"
Vue.use(VueTextareaAutosize)

// vee validate
import { ValidationProvider, ValidationObserver, extend, localize } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
localize("zh_TW", zh_TW);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// vue tour
import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
Vue.component('VueTour', VueTour);

const load_event = ( typeof Turbolinks == 'object' && Turbolinks.supported ) ? 'turbolinks:load' : 'DOMContentLoaded'
document.addEventListener(load_event, () => {
  /**
   * The following block of code may be used to automatically register your
   * Vue components. It will recursively scan this directory for the Vue
   * components and automatically register them with their "basename".
   *
   * Eg. ./components/OtherComponent.vue -> <other-component></other-component>
   * Eg. ./UI/ButtonComponent.vue -> <button-component></button-component>
   */

  // form app/frontend/components/admins component
  const admins_files = require.context("../components/admins", true, /\.vue$/i);
  admins_files.keys().map((key) => {
    const component = key.split("/").pop().split(".")[0];
    const import_source = key.split(".")[1];
    // With Lazy Loading
    Vue.component(component, () => import(`../components/admins${import_source}`).then(m => m.default));
  });

  // form app/frontend/components/bills component
  const bills_files = require.context("../components/bills", true, /\.vue$/i);
  bills_files.keys().map((key) => {
    const component = key.split("/").pop().split(".")[0];
    const import_source = key.split(".")[1];
    // With Lazy Loading
    Vue.component(component, () => import(`../components/bills${import_source}`).then(m => m.default));
  });

  Vue.mixin({
    data() {
      return {
        I18n,
        authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        current_path: window.location.pathname,
      };
    },
  });
  const vm = new Vue({
    // store,
  });
  vm.$mount("#app");
});
