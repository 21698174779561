const toastr = require('toastr');
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: true,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
window.Toastr = (type, message, title) => {
  toastr[type](message, title);
};

document.addEventListener("DOMContentLoaded", () => {
  if(gon.toastr){
    gon.toastr.forEach((toastr) => {
      Toastr(toastr.type, toastr.message, toastr.title);
    })
  }
})