<template>
  <div class="mb-3">
    <div class="card mb-3">
      <div class="card-body">
        <div class="mb-0 table-responsive" v-if="reviews.length > 0">
          <table class="table datatable table-hover w-100">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                  data-visible="false"
                >
                  {{ I18n.t("submitted_at", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("reviewable", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("current_content", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("event", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t("content_changes", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.models.company') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.attributes.contract.client_discount_rate') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="false"
                  data-class-name="text-center align-middle"
                >
                  {{ I18n.t('activerecord.attributes.contract.tax_percent') }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                  v-if="['approved', 'rejected'].includes(status)"
                >
                  {{ I18n.t("reviewed_at", locale_scope) }}
                </th>
                <th
                  scope="col"
                  class="text-center align-middle"
                  data-sortable="true"
                  data-class-name="text-center align-middle"
                  v-if="['approved', 'rejected'].includes(status)"
                >
                  {{ I18n.t("reviewer", locale_scope) }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="review in reviews"
                :key="`review_${review.id}`"
              >
                <td scope="row">{{ review.submitted_at }}</td>
                <td class="text-center align-middle">
                  <span v-html="review.show"></span>
                </td>
                <td class="text-center align-middle">
                  <span v-html="review.current_content"></span>
                </td>
                <td class="text-center align-middle">
                  {{ review.event }}
                </td>
                <td class="text-center align-middle">
                  <review-modal :review="review" :title="review.current_content"></review-modal>
                </td>
                <td class="text-center align-middle">
                  {{ review.company }}
                </td>
                <td class="text-center align-middle">
                  {{ review.client_discount_rate }}
                </td>
                <td class="text-center align-middle">
                  {{ review.tax_percent }}
                </td>
                <td
                  class="text-center align-middle"
                  v-if="['approved', 'rejected'].includes(status)"
                >
                  {{ review.reviewed_at }}
                </td>
                <td class="text-center align-middle"  v-if="['approved', 'rejected'].includes(status)">
                  {{ review.reviewer }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h6 class="card-title" v-else>{{ I18n.t('admins.reviews.index.no_review_item') }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ReviewModal from './ReviewModal.vue';
export default {
  components: {
    ReviewModal,
  },
  props: {
    status: {
      type: String,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      locale_scope: {
        scope: "activerecord.attributes.review",
      },
      sort_column: '0',
      reviews: [],
      dataTable: null,
    };
  },
  mounted() {
    this.getReviews();
  },
  methods: {
    getReviews() {
      axios.get(this.url).then((response) => {
        let data = response.data
        this.reviews = data;
        this.$nextTick(() => {
          if (this.dataTable) return this.dataTable.draw();

          this.dataTable = $(this.$el.querySelector(".datatable"))
            .DataTable({
              responsive: true,
              order: [[this.sort_column, "desc"]],
              language: {
                url: `/datatable.${I18n.currentLocale()}.lang.json`,
              },
            })
            .api().columns.adjust();
        });
      });
    },
  },
};
</script>
