<template>
  <div>
    <billing-file-index :authenticity_token="authenticity_token" v-if="authenticated"></billing-file-index>
    <div class="simple_form" v-else>
      <div class="form-group">
        <label for="password">{{ I18n.t('activerecord.attributes.manager.password') }}</label>
        <input type="text" id="password" class="form-control" v-model="password" @keyup.enter="submitAuth">
      </div>
      <div class="form-group">
        <button class="btn btn-success" @click.prevent="submitAuth">
          {{ I18n.t('submit') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';

  export default {
    data(){
      return{
        password: null,
        authenticity_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        authenticated: false
      }
    },
    methods: {
      submitAuth(){
        const data = { password: this.password, authenticity_token: this.authenticity_token }
        axios.post(`${window.location.pathname}/auth.json`, data)
          .then(({data}) => {
            const toast_type = data.type
            const toast_message = data.message
            Toastr(toast_type, toast_message)
            this.authenticated = (toast_type == 'success')
          }).catch(() => {
            Toastr('error', I18n.t('error'))
          })
      }
    }
  }
</script>
